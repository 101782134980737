<script setup lang="ts">
import SvgVue from "@/application/components/SvgVue";
import { appUrl, isWebPlatform } from "@/application/utils/envInfo";
import { useBaseDataStore } from "@/user/store/baseDataStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import FlatFooter from "@/application/components/FlatFooter.vue";

const { maklername, maklertelefon, makleremail, maklerIsAgency, maklerOrAgencyUid, firmenname } = storeToRefs(useBaseDataStore());
const logoUrl = computed(() => {
  const maklerOrAgency = maklerIsAgency.value ? 'f' : 'm'
  const byUidPostFix = maklerOrAgencyUid.value !== null ? `/${maklerOrAgency}/${maklerOrAgencyUid.value}` : ''
  return `${appUrl}/api/makler/logo${byUidPostFix}`
})
</script>

<template>
  <Teleport to="#app-header" />

  <Teleport to="#app-page-head">
    <div class="flex-none scontainer md:flex flex-row items-center lg:min-h-16 gap-y-2 gap-x-4 pt-safe">
      <div v-if="maklername" class="mt-4 w-full md:flex flex-row items-center">
        <div class="h-16 max-w-56 max-md:w-1/2 flex items-center md:mr-4">
          <img
            class="max-h-full max-w-full"
            :src="logoUrl"
            :alt="`Logo ${firmenname}`"
          >
        </div>
        <div>
          <div class="font-medium">{{ maklername }}</div>
          <div v-if="maklername !== firmenname" class="font-light">{{ firmenname }}</div>
        </div>
      </div>
    </div>
  </Teleport>

  <!--<div class="overscrollBg"></div>-->

  <div class="bg-white flex grow">
    <div class="scontainer mt-4 pb-8 grow grid max-lg:grid-rows-[auto_1fr] grid-cols-1 lg:grid-cols-2 xl:grid-cols-[600px_1fr] gap-6 lg:gap-14 xl:gap-24">
      <div class="md:mt-6 order-2 lg:order-1 space-y-8 lg:space-y-10 mx-auto">
        <RouterView />
      </div>

      <div class="order-1 lg:order-2">
        <div class="sticky top-4 lg:top-20">
          <div class="min-h-[30vh] md:min-h-[50vh] lg:min-h-[70vh] rounded-2xl welcome-image">
            <div class="p-3 top-0 rounded-2xl overflow-hidden inline-block" style="backdrop-filter: blur(20px)">
              <div class=" w-48 lg:w-56">
                <SvgVue icon="bannerHorizontal.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Teleport to="#app-page-footer">
    <div v-if="maklername" class="space-y-6 border-t border-gray-100 bg-white">
      <div class="scontainer py-6 lg:py-10">
        <div class="grid grid-cols-1 lg:grid-cols-[1fr_3fr] items-end gap-x-20 gap-y-4">
          <div class="font-medium md:font-semibold text-lg md:text-2xl leading-tight">
            <span class="whitespace-nowrap">Sie haben Fragen?</span> <span class="whitespace-nowrap">Kontaktieren Sie {{ maklerIsAgency ? 'uns' : 'mich' }} gerne!</span>
          </div>
          <div class="flex flex-row flex-wrap gap-x-20 gap-y-3 md:gap-y-8">
            <div>
              <div class="max-sm:text-sm text-muted">Ansprechpartner</div>
              <div class="font-semibold md:text-lg">{{ maklername }}</div>
            </div>
            <div v-if="maklertelefon">
              <div class="max-sm:text-sm text-muted">Telefon</div>
              <div class="font-semibold md:text-lg">{{ maklertelefon }}</div>
            </div>
            <div v-if="makleremail">
              <div class="max-sm:text-sm text-muted">E-Mail</div>
              <div class="font-semibold md:text-lg">{{ makleremail }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isWebPlatform"
      class="lg:fixed lg:bottom-16 lg:right-16 max-lg:bg-white max-lg:border-t border-gray-100 max-lg:py-10 flex flex-row gap-x-10 lg:gap-3 justify-center"
    >
      <div class="flex flex-col items-center bg-white rounded-lg pt-1 pb-2 px-2 lg:shadow-xl lg:border-4 lg:border-blue-700">
        <div class="max-lg:order-2  w-28">
          <SvgVue icon="qr_appstore.svg" class="w-auto h-full " alt="" />
        </div>
        <a href="https://apps.apple.com/de/app/finanzmanager-app/id6648793330" target="_blank" class="block h-10 ">
          <SvgVue icon="appstore.svg" class="w-auto h-full" alt="" />
        </a>
      </div>
      <div class="flex flex-col items-center bg-white rounded-lg pt-1 pb-2 px-2 lg:shadow-xl lg:border-4 lg:border-blue-700">
        <div class="max-lg:order-2 w-28">
          <SvgVue icon="qr_playstore.svg" class="w-auto h-full " alt="" />
        </div>
        <a href="https://play.google.com/store/apps/details?id=de.demv.finanzmanager.app" target="_blank" class="block h-10 ">
          <SvgVue icon="playstore.svg" class="w-auto h-full" alt="" />
        </a>
      </div>
    </div>

    <FlatFooter class="pb-safe px-safe" />
  </Teleport>

  <Teleport to="#app-footer" />

  <Teleport to="#app-overlay" />
</template>

<style scoped>
.welcome-image {
  background-size: cover;
  background-position: center center;
  background-image: url('../../../../assets/welcome.jpg');
}
</style>

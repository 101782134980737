import { NotificationVariant, useNotification } from '@demvsystems/design-components';
import type { Ref } from 'vue';
import { ref } from 'vue';
import { useRequest } from "@/api/lib/integration";
import { ValidationError } from "@/api/lib/errors";
import type { Api } from "@/api/lib/types";

type ApiWithFormData<FormDataType extends object> = {
  validationErrors: Ref<Record<string, string[]>>,
  callWasSuccessful: Ref<null | boolean>,
  isLoading: Ref<boolean>,
  submit: (data: FormDataType) => Promise<void>,
  resetError: (key: string) => void,
  resetErrors: () => void,
};

export function useApiWithFormData<FormDataType extends Record<string, unknown>, ApiResponseType>(
  endpoint: Api<FormDataType, ApiResponseType>,
  successMessage: string,
  onSuccess: ((response: ApiResponseType) => void),
  onError: (() => void) | null = null,
  validationErrorsMessage = 'Bitte überprüfen sie Ihre Eingaben.',
): ApiWithFormData<FormDataType> {
  const validationErrors = ref<Record<string, string[]>>({});
  const isLoading = ref(false);
  const callWasSuccessful = ref<null | boolean>(null);
  const { show } = useNotification();

  const resetError = (key: string) => {
    delete validationErrors.value[key];
  };

  const resetErrors = () => validationErrors.value = {}

  const submit = async (data: FormDataType) => {
    isLoading.value = true;

    const { call } = useRequest(endpoint, (response: ApiResponseType) => {
      isLoading.value = false;
      validationErrors.value = {};
      show(successMessage, NotificationVariant.Success);
      callWasSuccessful.value = true;
      onSuccess(response)

    }, (error) => {

      isLoading.value = false;

      if (error instanceof ValidationError) {

        validationErrors.value = error.errors;
        show(validationErrorsMessage, NotificationVariant.Error);
        if (onError) onError();
        return

      }

      if (onError) onError();
      show(`Ein unerwarteter Fehler ist aufgetreten. ${error.message}`, NotificationVariant.Error);
    })

    await call(data);
  };

  return {
    validationErrors,
    callWasSuccessful,
    isLoading,
    submit,
    resetError,
    resetErrors,
  };
}

<template>
  <DetailViewContainer>
    <template #title>
      <div class="flex flex-row justify-between">
        <h3 class="text-gray-900" v-text="sparte" />
        <ButtonWithMenu
          v-if="contract.userContractId"
          data-testid="menu-button"
        >
          <template #button="{ open: isOpen, toggle }">
            <DsIcon
              name="ellipsis"
              variant="regular"
              size="lg"
              class="text-gray-700 hover:bg-gray-100 px-2 py-1.5 rounded hover:cursor-pointer"
              :class="{'bg-gray-100': isOpen}"
              @click="toggle"
            />
          </template>
          <ul class="-my-2 -mx-4 text-gray-800 text-sm">
            <li>
              <button
                class="w-full text-start hover:bg-gray-100 px-5 py-2 whitespace-nowrap"
                :class="{'cursor-not-allowed opacity-50' : !contract.canBeDeleted}"
                :disabled="!contract.canBeDeleted"
                @click="showUpdateModal = true"
              >
                <DsIcon
                  name="pencil"
                  variant="light"
                  size="sm"
                  class="mr-2"
                />
                Vertrag bearbeiten
              </button>
            </li>
            <li>
              <button
                class="w-full text-start text-red-700 hover:bg-gray-100 px-5 py-2 whitespace-nowrap"
                :class="{'cursor-not-allowed opacity-50' : !contract.canBeDeleted}"
                :disabled="!contract.canBeDeleted"
                @click="handleDelete(contract.userContractId)"
              >
                <DsIcon
                  name="trash"
                  variant="light"
                  size="sm"
                  class="mr-2"
                />
                Vertrag löschen
              </button>
            </li>
          </ul>
        </ButtonWithMenu>
        <UpdateContractModal
          :show="showUpdateModal"
          :contract="contract"
          @close="showUpdateModal = false"
        />
      </div>
    </template>
    <template #header>
      <div class="flex items-center">
        <div class="flex items-center">
          <CompanyIcon :id="contract.company.id" />

          <small
            class="ml-2.5 text-muted"
            v-text="contract.company.name"
          />
        </div>

        <DsModal
          ref="modal"
          title="Vertrag löschen"
          :variant="ModalVariant.Error"
          icon="trash"
          cancel-label="Abbrechen"
          confirm-label="OK"
        >
          Das Löschen des Vertrags kann nicht rückgängig gemacht werden. Sind Sie sicher, dass sie fortfahren möchten?
        </DsModal>
      </div>
    </template>

    <template #main>
      <div class="flex flex-col divide-y divide-gray-100">
        <template
          v-for="(detail, index) in contractDetails"
          :key="index"
        >
          <div
            v-if="detail.if"
            class="flex flex-row items-start justify-between gap-2 py-3"
          >
            <span class="font-normal text-gray-500 text-sm w-2/5 md:max-w-1/3 shrink-0">
              {{ detail.label }}
            </span>
            <component
              :is="detail.component"
              v-bind="detail.props"
            />
          </div>
        </template>
      </div>

      <DsAlert
        v-if="contract.note"
        type="info"
        icon="circle-info"
        label="Maklerbemerkung"
        class="mt-5"
      >
        {{ contract.note }}
      </DsAlert>
    </template>

    <template #extra>
      <ShallowCardCollapsible
        :title="`Dokumente (${contract.documents.length})`"
        :disabled="!hasDocuments"
        :open="hasDocuments && !isMobile"
        :max-height="'27rem'"
      >
        <ContractDocuments :documents="contract.documents" />
      </ShallowCardCollapsible>
    </template>
  </DetailViewContainer>
</template>

<script setup lang="ts">
import {
  DsAlert,
  DsIcon,
  DsModal,
  ModalVariant,
} from '@demvsystems/design-components';
import { captureException } from '@sentry/vue';
import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import type { Contract } from '@/api/contracts/types';
import CompanyIcon from '@/application/components/CompanyIcon.vue';
import ShallowCardCollapsible from '@/application/components/ShallowCardCollapsible.vue';
import { useDetectMobile } from '@/application/composables/detectMobile';
import ContractDocuments from '@/contracts/components/ContractDocuments.vue';
import DetailViewContainer from '@/contracts/components/DetailViewContainer.vue';
import { useContractStore } from '@/contracts/stores/contractStore';
import { spartenMapper } from '@/application/utils/sparteFormatter';
import { useRouter } from "vue-router";
import { call } from "@/api/lib/integration";
import { deleteContract } from "@/api/contracts/contractsApi";
import ButtonWithMenu from '@/application/components/ButtonWithMenu.vue';
import UpdateContractModal from '@/contracts/components/UpdateContractModal.vue';
import { useContractDetails } from '@/contracts/composables/contractDetails';

type Props = {
  contract: Contract
}
const props = defineProps<Props>();

const modal: Ref<typeof DsModal | null> = ref(null);
const showUpdateModal = ref(false);
const { isMobile } = useDetectMobile();

const sparte = computed(() => spartenMapper(props.contract.product));
const hasDocuments = computed(() => props.contract.documents.length > 0);
const router = useRouter()

function handleDelete(userContractId: number | null) {
  modal.value?.open({
    confirmed: () => {
      deleteUserContract(userContractId);
    },
  });
}

async function deleteUserContract(userContractId: number | null) {
  if (userContractId === null) return;

  await call(
    deleteContract({ userContractId }),
    async () => {
      useContractStore().removeUserContract(userContractId);
      await router.push('/meine-vertraege');
    },
    (error) => {
      captureException(error)
    },
  );
}

const { contractDetails } = useContractDetails(toRef(() => props.contract));
</script>

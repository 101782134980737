import type {
  ContractsResponse,
  DepotsResponse,
  MetaUpdateContract,
  UpdateContractResponse
} from '@/api/contracts/types';
import type { ContractData } from '@/contracts/types/types';
import { useDelete, useGet, usePost, usePut } from '@/api/lib/integration';

export const createContract = usePost<ContractData, { contract_id: number }>('/api/vertraege');
export const updateContract = usePut<MetaUpdateContract, UpdateContractResponse>('/api/vertraege/{data.id}');
export const getContracts = useGet<void, ContractsResponse>('/api/vertraege');
export const getDepots = useGet<void, DepotsResponse>('/api/depots');
export const deleteContract = useDelete<{ userContractId: number }, void>('/api/vertraege/{userContractId}');

<template>
  <DsForm
    :validation-errors="errors"
    class="w-full"
    @submit.prevent
  >
    <DsAlert v-if="errorMsg" type="error" class="mb-5">{{ errorMsg }}</DsAlert>

    <DsFormGroup
      validation-name="email"
      label="E-Mail Adresse"
      class="mb-3"
    >
      <DsInput
        v-model="form.email"
        immediate
        type="email"
        :disabled="loading"
        autocomplete="email"
        size="lg"
        required
      />
    </DsFormGroup>

    <DsFormGroup
      validation-name="password"
      label="Passwort"
    >
      <DsInput
        v-model="form.password"
        name="password"
        :disabled="loading"
        autocomplete="password"
        size="lg"
        required
        :type="showPassword ? 'default' : 'password'"
      />
    </DsFormGroup>

    <div class="mt-6 lg:mt-10 space-y-2">
      <DsButton
        type="submit"
        variant="primary"
        size="lg"
        class="w-full"
        :handler="submit"
      >
        Anmelden
      </DsButton>
      <div class="max-lg:hidden text-muted text-center">Oder</div>
      <DsButton
        :disabled="loading"
        variant="outline"
        size="lg"
        class="w-full"
        @click="
          $emit('switch-to-mail');
          useTagManager().push({ event: TrackEventCategory.LOGIN_VIA_MAIL, action: 'zur E-Mail Anmeldung' })
          trackEvent({category: TrackEventCategory.LOGIN_VIA_MAIL , action: 'Über E-Mail anmelden', name: 'click'})
        "
      >
        Ohne Passwort anmelden
      </DsButton>
    </div>
  </DsForm>
</template>

<script setup lang="ts">
import {
  DsAlert,
  DsButton,
  DsForm,
  DsFormGroup,
  DsInput,
} from '@demvsystems/design-components';
import { onMounted, ref } from 'vue';

import { trackEvent } from '@/application/utils/matomo';
import { TrackEventCategory } from '@/application/utils/matomo/events';

import { useForm } from "@/api/lib/integration";
import { passwordLogin } from "@/api/auth/authApi";
import { getDeviceName, isNativePlatform } from "@/application/utils/envInfo";
import { useTagManager } from '@demv_systems/feu-tag-manager';

const emit = defineEmits(['switch-to-mail', 'two-factor-challenge']);
const showPassword = ref(false);

const { form , errors, submit, errorMsg, loading } = useForm(
  passwordLogin,
  { email: '', password: '' },
  async (requestAccessToken) => {
    emit('two-factor-challenge', requestAccessToken);
  }
);

defineExpose({ form, errors });

onMounted(async () => {
  if (isNativePlatform) {
    // @ts-ignore
    form.value.deviceName = await getDeviceName();
  }
})
</script>

<script setup lang="ts">
import { ref } from 'vue';
import { DsAlert, DsButton, DsCard } from '@demvsystems/design-components';
import { storeToRefs } from 'pinia';
import PersonalDataForm from '@/user/components/PersonalDataForm.vue';
import PersonalDataFormSkeleton from '@/user/components/PersonalDataFormSkeleton.vue';
import Heading from '@/application/components/Heading.vue';
import { useBaseDataStore } from '@/user/store/baseDataStore';
import { useUserDataStore } from '@/user/store/userDataStore';
import BankAccountCard from '@/contracts/components/BankAccountCard.vue';
import CreateBankAccountModal from '@/user/components/CreateBankAccountModal.vue';
import BankAccountsSkeleton from '@/user/components/BankAccountsSkeleton.vue';
import { useContractStore } from '@/contracts/stores/contractStore';
import PageContentWrapper from '@/application/layouts/ContentWrapper/PageContentWrapper.vue';

const errorMessage = "Es gab einen Fehler. Bitte laden Sie die Seite neu oder probieren Sie es zu einem späteren Zeitpunkt nochmal.";

const { isCompany } = storeToRefs(useBaseDataStore());
const { userData, userDataLoading, userDataError, bankAccounts, bankAccountsLoading, bankAccountsError } = storeToRefs(useUserDataStore());
const { fetchContractsAndDepots } = useContractStore();
const { fetchUserContacts, getBankAccounts } = useUserDataStore();

const showCreateModal = ref<boolean>(false);

fetchUserContacts();
getBankAccounts();
fetchContractsAndDepots();
</script>

<template>
  <PageContentWrapper>
    <div class="flex flex-col md:w-10/12 lg:w-8/12 gap-2 -mx-4 md:mx-auto">
      <h1 class="hidden md:block text-center">
        {{ isCompany ? 'Unsere Daten' : 'Meine Daten' }}
      </h1>

      <span class="md:hidden mx-4 font-semibold text-lg">Kontaktinformationen</span>
      <DsCard class="mb-10">
        <Heading
          level="5"
          text="Kontaktinformationen"
          :icon="{name: 'user-circle', variant: 'regular', class: 'text-secondary mr-2'}"
          class="hidden md:block border-b border-gray-200 pb-2 mb-6 font-semibold"
        />
        <PersonalDataFormSkeleton v-if="userDataLoading" />
        <PersonalDataForm v-else-if="userData" />
        <DsAlert
          v-else-if="userDataError"
          class="my-10"
          type="error"
          label="Es ist leider ein Fehler aufgetreten."
        >
          {{ errorMessage }}
        </DsAlert>
      </DsCard>

      <div class="md:hidden flex flex-row items-center justify-between mx-4 mt-5">
        <span class="md:hidden font-semibold text-lg">Bankverbindungen</span>
        <DsButton
          class="inline-block float-right"
          variant="secondary"
          icon="plus"
          @click="showCreateModal = true"
        >
          hinzufügen
        </DsButton>
      </div>
      <DsCard class="mb-10">
        <Heading
          level="5"
          text="Bankverbindungen"
          :icon="{
            name: 'money-check-dollar',
            variant: 'regular',
            size: 'lg',
            class: 'text-secondary mr-2',
          }"
          class="hidden md:block border-b border-gray-200 pb-2 mb-6 font-semibold"
        >
          <DsButton
            class="inline-block float-right -mt-1"
            variant="secondary"
            icon="plus"
            @click="showCreateModal = true"
          >
            Bankverbindung hinzufügen
          </DsButton>
        </Heading>

        <BankAccountsSkeleton v-if="bankAccountsLoading" />
        <DsAlert
          v-else-if="bankAccountsError"
          class="my-10"
          type="error"
          label="Es ist leider ein Fehler aufgetreten."
        >
          {{ errorMessage }}
        </DsAlert>
        <div
          v-else-if="bankAccounts?.length > 0"
          class="space-y-5 pt-2 pb-1"
        >
          <BankAccountCard
            v-for="(account, idx) in bankAccounts"
            :key="idx"
            :account="account"
          />
        </div>
        <div
          v-else
          class="flex flex-row p-4 my-4 border bg-gray-50 rounded-lg"
        >
          <span class="text-center w-full">Keine Bankverbindungen vorhanden.</span>
        </div>
      </DsCard>
    </div>

    <CreateBankAccountModal
      :show="showCreateModal"
      @close="() => showCreateModal = false"
    />
  </PageContentWrapper>
</template>

import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { captureException } from '@sentry/vue';
import { useBaseDataStore } from '@/user/store/baseDataStore.ts';

function getAcceptedEmbeddings(): { gaAccepted: boolean, gtmAccepted: boolean } {
  if (!window.CCM?.acceptedEmbeddings) {
    return { gaAccepted: false, gtmAccepted: false };
  }

  const acceptedEmbeddings = window.CCM.acceptedEmbeddings;

  const gaAccepted = acceptedEmbeddings.some(
    embedding => embedding.name === 'Google Analytics 4'
  )

  const gtmAccepted = acceptedEmbeddings.some(
    embedding => embedding.name === 'Google Tag Manager'
  );


  return { gaAccepted, gtmAccepted };
}

export async function handleConsent() {
  const { gaAccepted, gtmAccepted } = getAcceptedEmbeddings();

  try {
    await FirebaseAnalytics.setEnabled({
      enabled: gaAccepted && gtmAccepted,
    })

    await FirebaseAnalytics.setUserId({
      userId: useBaseDataStore().hashedUserId,
    })

  } catch (error) {
    captureException(error);
  }
}

import type { Ref } from 'vue';
import { ref } from 'vue';

import { getGesellschaften } from '@/api/common/commonApi';
import type { Gesellschaft } from '@/api/common/types';
import { call } from "@/api/lib/integration";

enum KFZ {
  PRIVATE_SACHVERSICHERUNG = 171,
  PRIVATE_SACHVERSICHERUNG_KFZ_ANHAENGER = 310,
  GEWERBLICHE_SACHVERSICHERUNG = 179,
  GEWERBLICHE_SACHVERSICHERUNG_KFZ_ANHAENGER = 409,
  GEWERBLICHE_SACHVERSICHERUNG_KFZ_HANDEL_HANDWERK = 506,
}

const kfzSparten = new Set([
  KFZ.PRIVATE_SACHVERSICHERUNG,
  KFZ.PRIVATE_SACHVERSICHERUNG_KFZ_ANHAENGER,
  KFZ.GEWERBLICHE_SACHVERSICHERUNG,
  KFZ.GEWERBLICHE_SACHVERSICHERUNG_KFZ_ANHAENGER,
  KFZ.GEWERBLICHE_SACHVERSICHERUNG_KFZ_HANDEL_HANDWERK,
]);

const gesellschaften = ref<Gesellschaft[]>([]);

async function fetchGesellschaften(): Promise<void> {
  await call(
    getGesellschaften(),
    (data) => { gesellschaften.value = data },
  );
}

function isKfzSparte(sparteId?: number | null): boolean {
  return !!sparteId && kfzSparten.has(sparteId);
}

export const useGesellschaften = (): {
  gesellschaften: Ref<Gesellschaft[]>,
  fetchGesellschaften: () => Promise<void>
  isKfzSparte: (sparteId?: number | null) => boolean,
} => {

  return {
    gesellschaften,
    fetchGesellschaften,
    isKfzSparte,
  };
};

<script setup lang="ts">
import { DsAlert, DsButton, NotificationVariant, useNotification } from '@demvsystems/design-components';

import ContractForm from '@/contracts/components/ContractForm.vue';
import type { Contract } from '@/api/contracts/types';
import { formatDate } from '@/application/utils/date';
import { useLicensePlate } from '@/contracts/utils/licensePlate';
import { updateContract } from '@/api/contracts/contractsApi';
import { useContractStore } from '@/contracts/stores/contractStore';
import { useForm } from '@/api/lib/integration.ts';
import { trackEvent } from '@/application/utils/matomo';
import { TrackEventCategory } from '@/application/utils/matomo/events.ts';
import { useTagManager } from '@demv_systems/feu-tag-manager';

const props = defineProps<{ contract: Contract }>();
const emit = defineEmits(['close']);

const { fetchContractsAndDepots } = useContractStore();
const { show: showNotification } = useNotification();

const {
  unterscheidungszeichen,
  erkennungszeichen,
  erkennungsnummer,
  abschliessendeszeichen
} = useLicensePlate(props.contract.meta?.kfz?.licensePlate);

const intervalMap = new Map([
  ['jährlich', 1],
  ['halbjährlich', 2],
  ['vierteljährlich', 3],
  ['monatlich', 4],
  ['Einmalbetrag', 5],
]);

const {
  form,
  submit,
  reset,
  errors,
  updateField,
  errorMsg
} = useForm(
  updateContract,
  {
    data: {
      id: props.contract.id,
      sparte_id: props.contract.product.id || null,
      gesellschaft_id: props.contract.company.id || null,
      vertragsnummer: props.contract.number,
      beginn: props.contract.term?.start ? formatDate(props.contract.term.start) : null,
      ablaufdatum: props.contract.term?.end ? formatDate(props.contract.term.end) : null,
      beitrag: props.contract.payment?.zahlbeitrag || null,
      zahlweise: props.contract.payment?.interval && intervalMap.get(props.contract.payment.interval) || null,
      kennzeichen: {
        unterscheidungszeichen,
        erkennungszeichen,
        erkennungsnummer,
        abschliessendeszeichen,
      },
    },
    previous: {
      id: props.contract.id,
      sparte_id: props.contract.product.id || null,
      gesellschaft_id: props.contract.company.id || null,
      vertragsnummer: props.contract.number,
      beginn: props.contract.term?.start ? formatDate(props.contract.term.start) : null,
      ablaufdatum: props.contract.term?.end ? formatDate(props.contract.term.end) : null,
      beitrag: props.contract.payment?.zahlbeitrag || null,
      zahlweise: props.contract.payment?.interval && intervalMap.get(props.contract.payment.interval) || null,
      kennzeichen: {
        unterscheidungszeichen,
        erkennungszeichen,
        erkennungsnummer,
        abschliessendeszeichen,
      },
    },
  },
  async () => {
    emit('close');
    showNotification('Ihre Daten wurden erfolgreich gespeichert.', NotificationVariant.Success);
    await fetchContractsAndDepots(true);
    useTagManager().push({ event: TrackEventCategory.CONTRACT, action: 'bearbeiten' })
    trackEvent({ category: TrackEventCategory.CONTRACT, action: 'Bearbeitet', name: 'click' });
  },
  () => {},
  'data'
);

function close() {
  reset();
  emit('close');
}
</script>

<template>
  <div class="-mx-4 sm:-mx-6  overflow-y-auto">
    <div class="flex flex-col gap-4 px-4 sm:px-6 pt-5">
      <ContractForm
        :model-value="form.data"
        :validation-errors="errors"
        :update-field="updateField"
        update-form
      />

      <DsAlert
        v-if="errorMsg"
        :label="errorMsg"
        type="error"
        class="my-5"
      />

      <div class="sticky bottom-0.5 flex flex-row justify-center sm:justify-end gap-3 pt-2 bg-white">
        <DsButton
          variant="secondary"
          class="w-full sm:w-auto"
          size="lg"
          @click="close"
        >
          Abbrechen
        </DsButton>
        <DsButton
          variant="primary"
          class="w-full sm:w-auto"
          size="lg"
          :handler="submit"
        >
          Speichern
        </DsButton>
      </div>
    </div>
  </div>
</template>

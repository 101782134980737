import MainLayout from "@/application/layouts/MainLayout.vue";
import { Finanzmanager } from "@/application/router/types/types";
import ContractPage from "@/contracts/pages/ContractPage.vue";
import type { RouteRecordRaw } from "vue-router";
import ContractDetailsPage from "@/contracts/pages/ContractDetailsPage.vue";

export const contractRoutes: RouteRecordRaw[] = [
  {
    path: '/meine-vertraege',
    component: MainLayout,
    children: [
      {
        path: '',
        name: Finanzmanager.VERTRAEGE,
        component: ContractPage,
        meta: {
          title: 'Verträge',
        },
      },
      {
        path: ':id',
        name: Finanzmanager.VERTRAG,
        component: ContractDetailsPage,
        meta: {
          title: 'Vertrag',
        },
      }
    ],
  }
]

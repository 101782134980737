import { Finanzmanager } from "@/application/router/types/types";
import type { RouteRecordRaw } from "vue-router";
import MainLayout from "@/application/layouts/MainLayout.vue";
import DocumentPage from "@/documents/pages/DocumentPage.vue";

export const documentRoutes: RouteRecordRaw[] = [
  {
    path: '/meine-dokumente',
    component: MainLayout,
    meta: {
      title: 'Dokumente',
    },
    children: [
      {
        path: '',
        name: Finanzmanager.DOKUMENTE,
        component: DocumentPage,
        meta: {
          title: 'Dokumente',
        },
      }
    ]
  }
]

import { isPlainObject, merge } from 'lodash-es';
import type { CheckupState } from '@/checkup/types/types';

// https:// github.com/lodash/lodash/issues/2240#issuecomment-448982405
export const flatten = <T>(object: object, path: string[] = []): Record<string, T> => (
  (isPlainObject(object) || Array.isArray(object))
    ? Object.entries(object).reduce((accumulator, [k, v]) => merge(accumulator, flatten(v, [...path, k])), {})
    : { [path.join('.')]: object as T }
);

export function flattenCheckupState<T>(state: CheckupState): Record<string, T> {
  const flattenedState: Record<string, T> = {};

  for (const key in state) {
    Object.assign(flattenedState, state[key].value);
  }

  return flattenedState;
}

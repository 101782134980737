<script setup lang="ts">
import { DsButton } from "@demvsystems/design-components";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/user/store/userStore";
import SvgVue from "@/application/components/SvgVue";
import { BiometryType } from "@aparajita/capacitor-biometric-auth/dist/esm/definitions";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ref, watch } from "vue";

const { isAppLocked, biometry } = storeToRefs(useUserStore());
const buttonIcon = ref('');

watch(
  biometry,
  async (value) => {
    switch (value?.biometryType) {
      case BiometryType.touchId:
        buttonIcon.value = 'fingerprint';
        await unlock();
        break;
      case BiometryType.faceId:
        buttonIcon.value = 'face-viewfinder';
        break;
      case BiometryType.fingerprintAuthentication:
        buttonIcon.value = 'fingerprint';
        await unlock();
        break;
      case BiometryType.faceAuthentication:
        buttonIcon.value = 'face-viewfinder';
        break;
      case BiometryType.irisAuthentication:
        buttonIcon.value = 'face-viewfinder';
        break;
      default:
        buttonIcon.value = '';
    }
  },
  { immediate: true },
);

async function unlock(): Promise<void> {
  return await useUserStore().unlockApp();
}

async function handleUnlock() {
  useUserStore().resetAppUnlockErrorCode();
  await unlock();
}
</script>

<template>
  <Transition name="fade-out">
    <div
      v-if="isAppLocked"
      class="overlay h-dvh overflow-hidden grid w-full grid-rows-3 grid-cols-1 p-10 relative items-center"
      style="background: #2360B0;"
    >
      <div />
      <div>
        <SvgVue icon="logo.svg" alt="" class="w-32 mx-auto" />
      </div>
      <div class="pointer-events-auto">
        <div class="max-w-80 mx-auto ">
          <DsButton
            type="submit"
            variant="secondary"
            size="lg"
            class="w-full"
            :handler="handleUnlock"
          >
            <FontAwesomeIcon v-if="buttonIcon" :icon="['fal', buttonIcon]" class="mr-2" size="xl" />
            Entsperren
          </DsButton>
        </div>
      </div>
    </div>
  </Transition>
</template>

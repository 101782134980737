<script setup lang="ts">
import { DsBadge, DsIcon, NotificationVariant, useNotification } from '@demvsystems/design-components';
import { ref, computed, watch } from 'vue';
import { useHandlungStore } from '@/actions/store/actionStore';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router'
import type { Action }from '@/api/actions/types';
import HeadlineBlock from '@/actions/components/HeadlineBlock.vue';
import TextBlock from '@/actions/components/TextBlock.vue';
import ExternalLinkBlock from '@/actions/components/ExternalLinkBlock.vue';
import ChoiceBlock from '@/actions/components/ChoiceBlock.vue';
import SubmitBlock from '@/actions/components/SubmitBlock.vue';
import ActionDetailSkeleton from '@/actions/components/ActionDetailSkeleton.vue';
import ShallowCard from '@/contracts/components/ShallowCard.vue';
import { call } from '@/api/lib/integration';
import { logAction, submitAction } from '@/api/actions/actionsApi';
import { captureException } from '@sentry/vue';
import { Finanzmanager } from '@/application/router/types/types';
import PageContentWrapper from '@/application/layouts/ContentWrapper/PageContentWrapper.vue';
import { ForbiddenError } from "@/api/lib/errors.ts";

const componentMap = {
  headline: HeadlineBlock,
  text: TextBlock,
  externalLink: ExternalLinkBlock,
  choice: ChoiceBlock,
  submit: SubmitBlock,
} as const;
const router = useRouter();
const { id } = defineProps<{ id: number }>();
const { initializing } = storeToRefs(useHandlungStore());
const action = computed<null|Action>(() => useHandlungStore().getActionById(id));
const modelValues = ref<Record<string, string>>({});

watch(
  [initializing, action],
  () => !initializing.value && !action.value && router?.push({ name: Finanzmanager.HANDLUNGEN }),
    { immediate: true },
);

function toKeyValuePairs(obj: Record<string, string>): Array<{ id: string; selected_id: string }> {
  return Object.entries(obj).map(([id, selected_id]) => ({ id, selected_id }));
}

async function submit(submitId: number) {
  await logAction({
    actionId: id,
    context: 'submit',
    data: modelValues.value,
  });
  await call(submitAction({
      actionId: id,
      submit_id: submitId,
      bausteine: toKeyValuePairs(modelValues.value),
    }),
    (data) => {
      useHandlungStore().updateAction(data);
    },
    (error) => {
      useNotification().show(
        error instanceof ForbiddenError
          ? error.message
          : 'Ein unerwarteter Fehler ist aufgetreten',
        NotificationVariant.Error,
      );
      captureException(error);
    },
  )
}
</script>

<template>
  <PageContentWrapper>
    <div class="-mx-4 md:mx-0">
      <ShallowCard
        class="md:w-2/3 lg:w-1/2"
        no-hover
      >
        <ActionDetailSkeleton v-if="initializing || !action" />
        <div
          v-else-if="action.interaction_status === 'open'"
          class="flex flex-col grow gap-1"
        >
          <div class="flex flex-col items-start mb-2">
            <h3 class="text-gray-900 mb-0">
              {{ action.title }}
            </h3>
            <span
              v-if="action.caption"
              class="text-gray-700"
            >
              {{ action.caption }}
            </span>
            <DsBadge
              v-if="action.occasion"
              class="mt-2"
            >
              {{ action.occasion }}
            </DsBadge>
          </div>
          <component
            :is="componentMap[baustein.type]"
            v-for="baustein in action.bausteine"
            :key="baustein.id"
            :action-id="action.id"
            :baustein="baustein"
            :model-value="modelValues[baustein.id]"
            @update:model-value="value => modelValues[baustein.id] = value"
            @submit="(value) => submit(value)"
          />
        </div>
        <div
          v-else-if="action.interaction_status === 'done'"
          class="flex flex-row gap-4 items-center py-6 sm:py-6 md:px-2"
        >
          <div
            class="bg-green-100 text-green-700 mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-12 sm:w-12"
          >
            <DsIcon
              name="check"
              size="lg"
              variant="regular"
              fixed-width
            />
          </div>
          <p class="my-2">
            Die Handlung <span class="font-semibold text-gray-900">{{ action.title }}</span> wurde abgeschlossen und ist
            nicht mehr verfügbar.
          </p>
        </div>
      </ShallowCard>
    </div>
  </PageContentWrapper>
</template>

<style scoped>
::v-deep(h3) {
  margin-bottom: 0;
}
</style>

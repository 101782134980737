<script setup lang="ts">
import MenuHint from '@/application/menu/components/MenuHint.vue';
import { useMenuItems } from '@/application/menu/composables/menuItems';
import { useUserStore } from '@/user/store/userStore';

const { items } = useMenuItems();
const userStore = useUserStore()

</script>

<template>
  <nav
    v-if="userStore.isAuthenticated"
    class="hidden md:flex flex-row items-center justify-center gap-6 lg:gap-10"
  >
    <RouterLink
      v-for="{ title, to, unreadHints } in items"
      v-slot="{ href, navigate, isActive }"
      :key="title"
      :to="to()"
      custom
    >
      <a
        :href="href"
        :class="{'active-nav-button': isActive}"
        class="nav-button relative"
        @click="navigate"
      >
        {{ title }}
        <MenuHint :number="unreadHints" />
      </a>
    </RouterLink>
  </nav>

  <RouterLink
    v-else
    class="hidden md:block text-dark font-bold justify-self-center"
    to="/"
  >
    meine-finanzen.digital
  </RouterLink>
</template>

<style scoped>
.nav-button {
  @apply lg:text-lg text-gray-800;
  @apply hover:text-secondary hover:decoration-secondary;
  @apply hover:underline hover:decoration-[3px] hover:underline-offset-[17px];
}

.active-nav-button {
  @apply text-secondary underline decoration-secondary decoration-[3px] underline-offset-[17px];
}
</style>

<script setup lang="ts">

import { DsIcon } from '@demvsystems/design-components';
import { useRoute, useRouter } from 'vue-router';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useBedarfStore } from '@/bedarf/store/bedarfStore';
import { Finanzmanager } from '@/application/router/types/types';

const route = useRoute();
const router = useRouter();
const { verlauf } = storeToRefs(useBedarfStore());

const showHeaderBackNav = computed(() => {
  if (route.meta?.hideHeaderBackNav) return false;
  if (route.path === '/mein-bedarf/beratungsverlauf' && verlauf.value.length === 0) return false;
  if (route.path === '/beratung/verlauf' && verlauf.value.length === 0) return false;

  const segments = route.path.split('/').filter(Boolean);
  return segments.length >= 2;
});

const replaceDynamicSegments = (path: string): string => {
  return path.replace(/:([a-zA-Z0-9]+)/g, (match: string, key: string): string => {
    const param = route.params[key];
    return typeof param === 'string' ? param : match;
  });
};

function navigate() {
  const reversedPaths = route.matched
    .map(route => replaceDynamicSegments(route.path))
    .reverse();

  for (let i = 0; i < reversedPaths.length - 1; i++) {
    const currentPath = reversedPaths[i];
    const nextPath = reversedPaths[i + 1];

    if (currentPath !== nextPath) {
      return router.push({ path: nextPath });
    }
  }

  return router.push({ name: Finanzmanager.VERTRAEGE })
}
</script>

<template>
  <button
    v-if="showHeaderBackNav"
    class="flex gap-2 rounded-full hover:bg-gray-100 items-center py-1 md:py-0.5 px-2 text-gray-900 -ml-2"
    @click="navigate"
  >
    <DsIcon name="arrow-left-long" variant="regular" />
    <span class="hidden md:block">zurück</span>
  </button>
</template>

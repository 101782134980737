<script setup lang="ts" generic="T extends SubmitData">

import type { ActionBlockProps, SubmitData } from '@/api/actions/types';
import { DsButton, DsModal } from '@demvsystems/design-components';
import { computed, type Ref, ref } from 'vue';
import { storeToRefs } from "pinia";
import { useBaseDataStore } from "@/user/store/baseDataStore.ts";

const props = defineProps<ActionBlockProps<T>>();
const emit = defineEmits(['submit'])

const modal: Ref<typeof DsModal | null> = ref(null);
const clickedButtonLabel = ref<string | null>(null);

const { isBroker } = storeToRefs(useBaseDataStore());

const sortedButtons = computed(() => {
  return [...props.baustein.data.buttons].sort((a, b) =>
    (a.variant === 'primary' ? 1 : -1) - (b.variant === 'primary' ? 1 : -1)
  );
});

function showModal(id: string, label: string) {
  clickedButtonLabel.value = label;
  modal.value?.open({
    confirmed: () => {
      handleSubmit(id);
    },
  });
}

function handleSubmit(id: string) {
  emit('submit', id);
}
</script>

<template>
  <div class="flex flex-row flex-wrap gap-4 justify-end mt-4">
    <DsButton
      v-for="({ id, label, variant }) in sortedButtons"
      :key="id"
      :variant="variant"
      :disabled="isBroker"
      size="lg"
      @click="showModal(id, label)"
    >
      {{ label }}
    </DsButton>
    <DsModal
      ref="modal"
      title="Auswahl bestätigen"
      cancel-label="Abbrechen"
      confirm-label="Ok"
    >
      <p class="my-5">
        Sind Sie sicher, dass sie <span class="text-gray-900 font-semibold">{{ clickedButtonLabel }}</span> möchten?
      </p>
    </DsModal>
  </div>
</template>

import { type RouteRecordRaw } from 'vue-router';
import MainLayout from '@/application/layouts/MainLayout.vue';
import Verlauf from '@/beratung/pages/Verlauf.vue';
import Beratung from '@/beratung/pages/Beratung.vue';
import Bedarf from "@/beratung/pages/Bedarf.vue";
import { BeratungRoute } from "@/application/router/types/types";
import DummyQuizImplementation from '@/quiz/DummyQuizImplementation.vue';
import Overview from '@/beratung/pages/Overview.vue';

export const beratungRoutes: RouteRecordRaw[] = import.meta.env.VITE_BERATUNG_ENABLED !== 'true'
  ? []
  : [
    {
      path: '/beratung',
      component: MainLayout,
      children: [
        {
          path: '',
          name: BeratungRoute.OVERVIEW,
          component: Overview,
        },
        {
          path: ':id',
          children: [
            {
              path: '',
              name: BeratungRoute.BERATUNG,
              component: Beratung,
              props: route => ({ id: route.params.id }),
              meta: {
                title: 'Beratung'
              },
            },
            {
              path: 'bedarf/:bedarfId',
              name: BeratungRoute.BEDARF,
              props: route => ({ beratungId: route.params.id, id: Number(route.params.bedarfId) }),
              component: Bedarf,
              meta: {
                title: 'Bedarf',
              },
            },
            {
              path: 'dummy-quiz',
              component: DummyQuizImplementation,
            }
          ]
        },
        {
          path: 'verlauf',
          name: BeratungRoute.VERLAUF,
          component: Verlauf,
          meta: {
            title: 'Beratungsverlauf'
          },
        },
      ]
    },
  ]

<script setup lang="ts">
import { DsModal } from '@demvsystems/design-components';
import ModalWrapper from "@/application/components/ModalWrapper.vue";
import MaklervollmachtForm from "@/Maklervollmacht/components/MaklervollmachtForm.vue";

defineProps<{ show: boolean }>();
const emit = defineEmits(['update:show']);
</script>

<template>
  <DsModal
    title="Maklervollmacht anfordern"
    :show="show"
    hide-buttons
    @cancel="emit('update:show', false)"
  >
    <ModalWrapper>
      <MaklervollmachtForm
        v-if="show"
        @success="emit('update:show', false)"
        @cancel="emit('update:show', false)"
      />
    </ModalWrapper>
  </DsModal>
</template>

<script setup lang="ts">
import {
  DsAccordion,
  DsAlert,
  DsButton, DsFieldError,
  DsForm,
  DsFormGroup,
  DsInput, DsPhoneNumberInput,
  NotificationVariant,
  useNotification
} from '@demvsystems/design-components';
import { requestMaklerVollmacht } from "@/api/maklerVollmacht/maklerVollmachtApi";
import { useForm } from "@/api/lib/integration";
import { onMounted, ref } from "vue";
import { useBaseDataStore } from "@/user/store/baseDataStore";
import { useUserDataStore } from "@/user/store/userDataStore";
import { storeToRefs } from "pinia";

const emit = defineEmits(['cancel', 'success']);
const { userData } = storeToRefs(useUserDataStore());
const invalidMobil = ref(false);

const { form, errors, submit, updateField, updateForm, loading } = useForm(
  requestMaklerVollmacht,
   {
    street: userData.value?.mainAddress?.street ?? '',
    houseNumber: userData.value?.mainAddress?.houseNumber ?? '',
    zipCode: userData.value?.mainAddress?.zipCode ? `${userData.value?.mainAddress?.zipCode}` : '',
    city: userData.value?.mainAddress?.city ?? '',
    mobile: userData.value?.mobile ?? '',
  },
  ({ status }) => {
    userData.value = {
      ...userData.value ?? {
        pwEmail: null,
        phone: null,
      },
      mainAddress: {
        street: form.value.street,
        city: form.value.city,
        houseNumber: form.value.houseNumber,
        zipCode: Number(form.value.zipCode),
      },
      mobile: form.value.mobile,
    };
    useBaseDataStore().maklervollmachtStatus = status;
    useNotification().show(
      `Der Maklervertrag bzw. -vollmacht wird vorbereitet. Dies kann einige Minuten dauern.`,
      NotificationVariant.Success,
    );
    emit('success');
  },
  () => {
    useNotification().show(
      `Möglicherweise sind noch nicht alle Vorausetzungen erfüllt. Versuchen Sie es zu einem späteren Zeitpunkt erneut.`,
      NotificationVariant.Error,
    );
  },
);

const saveSubmit = async () => {
  if (invalidMobil.value) return;

  await submit();
}

onMounted(async() => {
  if (!useUserDataStore().userData?.mainAddress) {
    loading.value = true;
    await useUserDataStore().fetchUserContacts();
    updateForm({
      street: userData.value?.mainAddress?.street ?? form.value.street ?? '',
      houseNumber: userData.value?.mainAddress?.houseNumber ?? form.value.houseNumber ?? '',
      zipCode: userData.value?.mainAddress?.zipCode ? `${userData.value?.mainAddress?.zipCode}` : form.value.zipCode ?? '',
      city: userData.value?.mainAddress?.city ?? form.value.city ?? '',
      mobile: userData.value?.mobile ?? form.value.mobile ?? '',
    })
    loading.value = false;
  }
});
</script>

<template>
  <div class="pb-4 pt-2">
    <p>
      Damit Ihr Berater Sie gegenüber den jeweiligen Versicherern vertreten kann, wird ein Maklervertrag bzw. -vollmacht benötigt.
      Diese Können Sie mit wenigen Klicks anfordern und unterschreiben. Prüfen Sie vorher bitte, ob Ihre Kontaktdaten vollständig und aktuell sind.
      Wenn Sie das Maklerdokument unterschrieben haben, können Sie das unter Dokumente aufrufen.
    </p>
    <DsAlert type="info" class="pt-3">
      <DsAccordion>
        <template #trigger>
          <h3 class="text-sm leading-5 font-semibold text-blue-800 -m-1 p-0">Wieso werden diese Daten benötigt?</h3>
        </template>
        <template #default>
          <ul class="pt-2 space-y-2">
            <li>
              Die Angabe Ihrer Adresse wird für den Maklervertrag bzw. -vollmacht benötigt.
            </li>
            <li>
              Die Handynummer ist für den Identifikationsprozess der Unterschrift notwendig.
              Bei Aufruf des Dokuments erhalten Sie einen 6-stelligen Code per SMS. (Ihnen entstehen keine Kosten)
            </li>
          </ul>
        </template>
      </DsAccordion>
    </DsAlert>
  </div>

  <DsForm :validation-errors="errors">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-4 md:flex-row justify-between">
        <DsFormGroup label="Straße" class="w-full" validation-name="street">
          <DsInput :model-value="form.street" size="lg" @update:model-value="updateField('street', $event)" />
        </DsFormGroup>
        <DsFormGroup label="Hausnummer" class="w-full md:w-52" validation-name="houseNumber">
          <DsInput :model-value="form.houseNumber" size="lg" @update:model-value="updateField('houseNumber', $event)" />
        </DsFormGroup>
      </div>
      <div class="flex flex-col gap-4 md:flex-row justify-between">
        <DsFormGroup label="Postleitzahl" class="w-full md:w-52" validation-name="zipCode">
          <DsInput :model-value="form.zipCode" mask="#####" size="lg" @update:model-value="updateField('zipCode', $event)" />
        </DsFormGroup>
        <DsFormGroup label="Stadt" class="w-full" validation-name="city">
          <DsInput :model-value="form.city" size="lg" @update:model-value="updateField('city', $event)" />
        </DsFormGroup>
      </div>
      <DsFormGroup validation-name="mobile" label="Mobilfunknummer">
        <DsPhoneNumberInput
          :model-value="form.mobile"
          immediate
          size="lg"
          mobile
          @update:model-value="updateField('mobile', $event)"
          @valid="invalidMobil = false"
          @invalid="invalidMobil = true"
        />
        <DsFieldError v-if="invalidMobil">Ungültiges Mobilfunknummer Format</DsFieldError>
      </DsFormGroup>
    </div>
  </DsForm>

  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
    <DsButton
      :disabled="invalidMobil"
      :handler="saveSubmit"
      size="lg"
      class="w-full sm:ml-3 sm:w-auto"
      variant="primary"
    >
      Jetzt anfordern
    </DsButton>
    <DsButton
      class="w-full sm:w-auto mt-3 sm:mt-0"
      size="lg"
      variant="secondary"
      @click="emit('cancel')"
    >
      Abbrechen
    </DsButton>
  </div>
</template>

<template>
  <div class="flex flex-col gap-3 min-w-1">
    <CardSparte
      class="py-1"
      :sparte="sparte"
    />
    <div class="flex flex-row justify-between">
      <CardGesellschaft
        :id="contract.company.id"
        :name="contract.company.name"
      />
      <ContractPayment
        v-if="contract.payment"
        :interval="contract.payment.interval"
        :amount="contract.payment.zahlbeitrag"
        inline
        bold
      />
    </div>
  </div>

  <CardMeta
    v-if="hasMetaInfo"
    class="py-1.5"
  >
    <div class="pr-2.5 flex flex-wrap grow items-center min-w-0 text-xs md:sm gap-3">
      <DsBadge v-if="!!contractType[contract.typeId]" class="bg-white">
        {{ contractType[contract.typeId] }}
      </DsBadge>
      <DsBadge v-if="!!contractStatus[contract.status.id]" type="warning">
        {{ contractStatus[contract.status.id] }}
      </DsBadge>
      <div v-if="licensePlate">
        <LicensePlate
          style="font-size: 12px"
          :license-plate="licensePlate"
        />
      </div>
      <div
        v-if="contract?.risikoort?.displayText"
        :title="`Risikoort ${contract.risikoort.displayText}`"
        class="truncate"
      >
        <DsIcon
          name="location-dot"
          variant="light"
          class="mr-1 text-xs md:text-sm"
        />
        {{ contract.risikoort.displayText }}
      </div>

      <div
        v-if="contract.owner"
        class="truncate text-xs md:text-sm"
        :title="`Mitversichert bei ${contract.owner.firstName} ${contract.owner.lastName}`"
      >
        <DsIcon
          name="users"
          variant="light"
          class="mr-1"
        />
        Mitversichert bei
        {{ contract.owner.firstName }}
        {{ contract.owner.lastName }}
      </div>
      <div
        v-else-if="numberCoInsured > 0"
        :title="coInsuredLabel"
        class="truncate text-xs md:text-sm"
      >
        <DsIcon
          name="users"
          variant="light"
          class="mr-1"
        />
        {{ coInsuredLabel }}
      </div>
      <div
        v-if="contract.nextStatus && new Date(contract.statusChangesAt) > new Date()"
        class="truncate text-xs md:text-sm"
      >
        <DsIcon
          v-if="statusIcon[contract.nextStatus]"
          :name="statusIcon[contract.nextStatus]"
          variant="light"
          class="mr-1"
        />
        {{ statuses[contract.nextStatus]?.toLowerCase() }} ab {{ formatDate(contract.statusChangesAt) }}
      </div>
    </div>
    <DsIcon
      v-if="contract.documents.length > 0"
      name="paperclip"
      variant="light"
      :title="contract.documents.length === 1 ? '1 Dokument' : `${contract.documents.length} Dokumente`"
      class="my-0.5 self-start"
    />
  </CardMeta>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { spartenMapper } from '@/application/utils/sparteFormatter';
import { DsBadge, DsIcon } from '@demvsystems/design-components';
import { useBaseDataStore } from '@/user/store/baseDataStore';
import type { Contract } from '@/api/contracts/types';
import CardGesellschaft from '@/contracts/components/CardGesellschaft.vue';
import CardMeta from '@/contracts/components/CardMeta.vue';
import CardSparte from '@/contracts/components/CardSparte.vue';
import ContractPayment from '@/contracts/components/ContractPayment.vue';
import LicensePlate from '@/contracts/components/LicensePlate.vue';
import { contractStatus, contractType } from '@/contracts/utils/labelFormatting';
import { formatDate } from '@/application/utils/date';
import { useContractStore } from '@/contracts/stores/contractStore.ts';

interface Props {
  contract: Contract,
}

const props = defineProps<Props>();

const { isCompany } = useBaseDataStore();
const { statuses } = useContractStore();

const sparte = computed(() => spartenMapper(props.contract.product))

const licensePlate = computed(() => {
  const meta = props.contract?.meta;
  return (meta && !Array.isArray(meta) && meta.kfz)
    ? meta.kfz.licensePlate
    : null
});
const hasMetaInfo = computed(() => !!(props.contract?.risikoort?.displayText
  || licensePlate.value
  || !!contractType[props.contract.typeId]
  || !!contractStatus[props.contract.status.id]
  || props.contract.owner
  || props.contract.coinsured?.length > 0
  || props.contract.statusChangesAt && new Date(props.contract.statusChangesAt) > new Date()
  || props.contract.documents.length > 0));

const numberCoInsured = computed(() => props.contract.coinsured.length);
const firstCoInsuredFullName = computed(() => `${props.contract.coinsured[0]?.firstName} ${props.contract.coinsured[0]?.lastName}`)

const coInsuredLabel = computed(() => {
  let label = isCompany ? 'Versicherte ' : 'Mitversicherte ';

  label += numberCoInsured.value > 1
    ? `Personen: ${firstCoInsuredFullName.value} + ${numberCoInsured.value - 1} weitere`
    : `Person: ${firstCoInsuredFullName.value}`;

  return label;
});

const statusIcon = {
  1: 'play',
  2: 'ban',
}
</script>

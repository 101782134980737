<script setup lang="ts">

import { useRoute } from "vue-router";

const route = useRoute();
</script>

<template>
  <span class="text-lg md:hidden font-medium min-w-0 truncate text-gray-900">{{ route.meta.title ?? 'meine-finanzen.digital' }}</span>
</template>

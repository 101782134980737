import { Finanzmanager } from "@/application/router/types/types";
import { useNewsStore } from "@/news/store/newsStore";
import News from "@/news/components/News.vue";
import type { RouteRecordRaw } from "vue-router";
import MainLayout from "@/application/layouts/MainLayout.vue";
import NewsPage from "@/news/pages/NewsPage.vue";

export const hintRoutes: RouteRecordRaw[] = [
  {
    path: '/hinweise',
    component: MainLayout,
    meta: {
      title: 'Hinweise',
    },
    children: [
      {
        path: '',
        name: Finanzmanager.HINWEISE,
        component: NewsPage,
        beforeEnter: async () => {
          await useNewsStore().fetchAllNews();
        },
        props: ({ query: { archived } }) => ({
          news: archived === 'true'
            ? useNewsStore().news.filter(({ isArchived }) => isArchived)
            : useNewsStore().news,
          archived: archived === 'true',
        }),
      },
      {
        path: ':id',
        name: Finanzmanager.HINWEISE_SHOW,
        component: News,
        beforeEnter: async ({ params: { id } }) => {
          await useNewsStore().fetchNews(Number(id));
        },
        props: () => useNewsStore().current,
        meta: {
          title: 'Hinweis',
        },
      },
    ]
  }

]

<template>
  <div :class="[inline ? 'flex flex-row-reverse items-center justify-end gap-1.5' : 'flex flex-col items-end']">
    <span
      v-if="cost"
      class="whitespace-nowrap text-gray-900"
      :class="{'font-semibold': bold, 'font-medium': !bold}"
    >{{ cost }}</span>
    <slot>
      <span
        v-if="interval"
        class="text-xs text-muted whitespace-nowrap"
      >
        {{ intervalName }}
      </span>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { formatDecimal } from '@/application/utils/money';

interface Props {
  amount: number | null,
  interval?: string | null,
  inline?: boolean,
  bold?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  inline: false,
  interval: null,
  bold: false,
});

const intervalMap = new Map([
  ['beitragsfrei', 'Beitragsfrei'],
  ['Einmalbetrag', '(Einmalig)'],
  ['halbjährlich', 'halbjährlich'],
  ['jährlich', 'jährlich'],
  ['monatlich', 'monatlich'],
  ['sonstiges', 'Sonstiges'],
  ['vierteljährlich', 'vierteljährlich'],
]);

const cost = computed(() => (props.amount !== null ? formatDecimal(props.amount) : ''));
const intervalName = computed(() => (props.interval !== null ? intervalMap.get(props.interval) : ''));
</script>

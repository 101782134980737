import type { LicencePlate } from "@/contracts/types/types";

export const useLicensePlate = (licensePlate?: string): LicencePlate => {

  const regex = /^([A-ZÄÖÜ]{1,3})-([A-ZÄÖÜ]{1,2})-([0-9]{1,4})([HE])$/;
  const match = licensePlate?.match(regex);

  return {
    unterscheidungszeichen: match?.[1] ?? null,
    erkennungszeichen: match?.[2] ?? null,
    erkennungsnummer: Number(match?.[3]) ?? null,
    abschliessendeszeichen: match?.[4] as 'H' | 'E' | undefined ?? null,
  };
};

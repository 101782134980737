<template>
  <div>
    <VueFormSlider
      :max="max"
      :min="min"
      :tooltips="tooltips"
      :step="step"
      :format="decimalDecoratedFormat"
      :orientation="orientation"
      :options="noUiSliderOptions"
      :model-value="modelValue"
      @update:model-value="emit('update:modelValue', $event)"
    />
    <div
      v-if="!hideMinMaxLabel"
      class="flex justify-between text-muted text-sm"
    >
      <div>{{ minLabel ?? min }}</div>
      <div>{{ maxLabel ?? max }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import VueFormSlider from '@vueform/slider';
import { computed } from 'vue';

const props = defineProps({
  hideMinMaxLabel: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number, Array, Object],
    default: 0,
  },
  minLabel: {
    type: [String, Number],
    default: null,
  },
  maxLabel: {
    type: [String, Number],
    default: null,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  format: {
    type: [Function, Object],
    default: () => ({}),
  },
  orientation: {
    type: String,
    default: 'horizontal',
  },
  noUiSliderOptions: {
    type: Object,
    default: () => ({}),
  },
  tooltips: {
    type: Boolean,
    default: true,
  },
  snap: {
    type: Boolean,
    default: false,
  },
  start: {
    type: Number,
    default: 0,
  },
});

// we need to set the decimal option if not passed to prevent long floating point errors like 5.00000002
// https://github.com/vueform/slider/issues/7
const decimalDecoratedFormat = computed(() => (
  typeof props.format === 'function' || props.format?.decimals != null
    ? props.format
    : ({ ...props.format, decimals: 0 })
));

const emit = defineEmits(['update:modelValue']);
</script>

<style lang="scss">
@use 'css/variables' as vars;
@use '@vueform/slider/themes/default.scss';

:root {
  --slider-connect-bg: #{vars.$blue};
  --slider-handle-ring-color: rgba(12, 122, 231, 0.19);
  --slider-tooltip-bg: #{vars.$blue};
}

.slider-origin {
  width: 100% !important;
  height: 100% !important;
}
</style>

<template>
  <PageContentWrapper>
    <h1 class="hidden md:block text-center mb-0">
      {{ archived ? 'Archivierte Hinweise' : 'Hinweise' }}
    </h1>
    <div class="flex flex-row justify-end">
      <DsButton
        variant="outline"
        icon="lightbulb"
        icon-variant="light"
        @click="show = true"
      >
        Einführung
      </DsButton>
      <OnboardingModal
        v-if="show"
        :show="show"
        @open="open"
        @close="close"
      />
    </div>
    <div class="row justify-center my-5">
      <div class="col text-center">
        <ul
          v-if="news.length > 0"
          class="flex flex-col gap-2"
        >
          <li
            v-for="({ id, title, createdAt }) in news"
            :key="id"
          >
            <DetailPageLink
              :title="title"
              :to="{ name: Finanzmanager.HINWEISE_SHOW, params: { id } }"
            >
              <DsBadge
                v-if="unreadHints[UnreadHintType.News].has(id)"
                type="primary"
                class="ml-3"
              >
                Neu
              </DsBadge>
              <template #meta>
                <span class="text-gray-500 text-xs flex-shrink-0 md:ml-auto">
                  {{ createdAt }}
                </span>
              </template>
            </DetailPageLink>
          </li>
        </ul>

        <div
          v-else
          class="text-center mt-2.5"
          v-text="'Keine Einträge'"
        />
      </div>
    </div>

    <div class="text-center">
      <RouterLink
        v-slot="{ navigate }"
        :to="{
          name: Finanzmanager.HINWEISE,
          query: { archived: archived ? 'false' : 'true'}
        } "
        custom
      >
        <DsButton
          variant="outline"
          :icon="archived ? 'mailbox' : 'box-archive'"
          icon-variant="light"
          @click="navigate"
        >
          {{ archived ? 'Hinweise' : 'Archiv' }}
        </DsButton>
      </RouterLink>
    </div>
  </PageContentWrapper>
</template>

<script setup lang="ts">
import { DsBadge, DsButton } from '@demvsystems/design-components';
import { ref } from 'vue';
import { useUnreadHintStore } from '@/application/store/unreadHintStore';

import type { News } from '@/api/news/types';
import OnboardingModal from '@/application/components/OnboardingModal.vue';
import { Finanzmanager } from '@/application/router/types/types';
import { UnreadHintType } from "@/api/unreadHint/types";
import DetailPageLink from "@/application/components/DetailPageLink.vue";
import PageContentWrapper from '@/application/layouts/ContentWrapper/PageContentWrapper.vue';

interface Props {
  news: News[]
  archived?: boolean,
}

defineProps<Props>();

const show = ref(false);

const open = () => {
  show.value = true;
};
const close = () => {
  show.value = false;
};

const unreadHints = useUnreadHintStore();
</script>

<script setup lang="ts">

import type { Ref } from 'vue';
import { onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useUserDataStore } from '@/user/store/userDataStore';
import type { BankAccount } from '@/api/bankAccount/types';
import BankAccountDetails from '@/user/components/BankAccountDetails.vue';
import PageContentWrapper from '@/application/layouts/ContentWrapper/PageContentWrapper.vue';

const { params } = useRoute()
const userDataStore = useUserDataStore();
const bankAccountId = Number(params.accountId);

onMounted(() => {
  return userDataStore.getBankAccount(bankAccountId)
})

const bankAccount: Ref<BankAccount | null> = computed(() => {
  return userDataStore.bankAccounts?.find(bankAccount => bankAccount.id === bankAccountId) ?? null;
})
</script>

<template>
  <PageContentWrapper>
    <BankAccountDetails
      v-if="bankAccount"
      :bank-account="bankAccount"
    />
  </PageContentWrapper>
</template>

import { Finanzmanager } from '@/application/router/types/types';
import type { RouteRecordRaw } from 'vue-router';
import MainLayout from "@/application/layouts/MainLayout.vue";
import ActionsPage from '@/actions/pages/ActionsPage.vue';
import ActionDetailPage from '@/actions/pages/ActionDetailPage.vue';

export const actionRoutes: RouteRecordRaw[] = [
  {
    path: '/handlungen',
    component: MainLayout,
    children: [
      {
        path: '',
        name: Finanzmanager.HANDLUNGEN,
        component: ActionsPage,
        meta: {
          title: 'Inbox',
        },
      },
      {
        path: ':id',
        name: Finanzmanager.HANDLUNG,
        component: ActionDetailPage,
        props: (route) => ({ id: Number(route.params.id) }),
        meta: {
          title: 'Offene Handlung',
        },
      }
    ],
  }
]

<template>
  <div class="flex items-center gap-1.5 ml-auto">
    <div
      class="h-2 w-2 rounded-full inline-block"
      :class="[getColorClass(status.name)]"
    />
    <span class="font-medium text-gray-900 text-right">{{ status.name }}</span>
  </div>
</template>

<script setup lang="ts">
import type { Status } from '@/api/contracts/types';

interface Props {
  status: Status,
}

defineProps<Props>();

const colorMap = new Map([
  ['aktiv', 'bg-green-700'],
  ['beitragsfrei', 'bg-blue-300'],
  ['ruhend', 'bg-gray-300'],
]);

function getColorClass(statusName: string): string {
  return colorMap.get(statusName.toLowerCase()) ?? 'bg-muted';
}
</script>

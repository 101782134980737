<script setup lang="ts">
import {
  DsForm,
  DsInput,
  DsButton,
  DsFormGroup,
  DsFieldError,
  DsSwitch,
} from '@demvsystems/design-components';
import { useApiWithFormData } from '@/application/composables/apiWithFormData';
import { createBankAccount, getIbanDetails } from '@/api/bankAccount/bankAccountsApi';
import { useDetectMobile } from '@/application/composables/detectMobile';
import { useFormHelper } from '@/application/composables/formHelper';
import type { CreateBankAccount } from '@/api/bankAccount/types';
import { storeToRefs } from 'pinia';
import { useBaseDataStore } from '@/user/store/baseDataStore';
import { call } from '@/api/lib/integration';
import { ref, watch } from 'vue';
import { useUserDataStore } from '@/user/store/userDataStore';
import SelectContracts from '@/user/components/SelectContracts.vue';
import ContractsField from '@/user/components/ContractsField.vue';
import { trackEvent, TrackEventCategory } from '@/application/utils/matomo/events';
import { useTagManager } from '@demv_systems/feu-tag-manager';

const emit = defineEmits(['close', 'updatedAccount']);

const { isMobile } = useDetectMobile();
const { fetchBankAccounts } = useUserDataStore();
const { kundeVorname, kundeNachname, isCompany, firmenname } = storeToRefs(useBaseDataStore());

const ibanDetailsError = ref<string | null>(null);
const showContracts = ref(false);
const showSelectContracts = ref(false);
const ibanDetailsLoading = ref(false);

const ibanFieldHasFocus = ref(false);
const isSubmitting = ref(false);

const { form, clear } = useFormHelper<CreateBankAccount>({
  depositor: isCompany.value ? firmenname.value : `${kundeVorname?.value} ${kundeNachname?.value}`,
  iban: null,
  bic: null,
  bankName: null,
  main: false,
  contractIds: [],
});

const { submit, validationErrors, resetError, resetErrors } = useApiWithFormData(createBankAccount,
  'Bankverbindung wurde hinzugefügt.',
  () => {
    useTagManager().push({ event: TrackEventCategory.BANK_ACCOUNT, action: 'erstellen' })
    trackEvent({ category: TrackEventCategory.BANK_ACCOUNT, action: 'Erstellen', name: 'click' });
    fetchBankAccounts();
    close();
  },
);

async function handleCreateAccount() {
  isSubmitting.value = true;
  showContracts.value = false;
  resetErrors();
  await submit({
    data: {
      depositor: form.depositor ?? '',
      iban: form.iban ?? '',
      bic: form.bic ?? '',
      bankName: form.bankName ?? '',
      main: form.main,
      contractIds: form.contractIds,
    },
    additional: {
      contracts: useUserDataStore().getBankAccountContractByIds(form.contractIds),
    }
  });
  isSubmitting.value = false;
}

function close() {
  resetErrors();
  ibanDetailsError.value = null;
  showSelectContracts.value = false;
  clear();
  emit('close');
}

async function setIbanDetails() {
  if (!form.iban) {
    form.bic = null;
    form.bankName = null;
    return;
  }

  ibanDetailsLoading.value = true;
  await call(getIbanDetails({ iban: form.iban }),
    (data) => {
      if ('bic' in data) {
        resetErrors();
        form.bic = data.bic;
        form.bankName = data.bankName;
      }
      ibanDetailsLoading.value = false;
    },
    (error) => {
      ibanDetailsError.value = error.message ?? 'Es gab einen Fehler';
      ibanDetailsLoading.value = false;
    },
  );
}

function onIbanFocus() {
  ibanFieldHasFocus.value = true;
  resetError('iban');
  ibanDetailsError.value = null;
}

function onIbanBlur() {
  ibanFieldHasFocus.value = false;
}

watch(ibanFieldHasFocus, (value) => {
  if (value) return;

  setTimeout(
    () => !isSubmitting.value && setIbanDetails(),
    250
  );
})
</script>

<template>
  <div class="flex flex-col">
    <div class="-mx-4 sm:-mx-6 overflow-y-auto pt-3">
      <Transition
        name="fade"
        mode="out-in"
        class="px-4 sm:px-6 py-2"
      >
        <DsForm
          v-if="!showSelectContracts"
          :validation-errors="validationErrors"
          @submit.prevent
        >
          <div class="flex flex-col gap-4">
            <DsFormGroup
              label="Kontoinhaber"
              :inline-reverse="!isMobile"
              validation-name="depositor"
              class="whitespace-nowrap"
            >
              <div class="w-full flex justify-end">
                <DsInput
                  v-model="form.depositor"
                  placeholder=""
                  class="w-full md:w-80"
                  size="lg"
                  @focus="resetError('depositor')"
                />
              </div>
            </DsFormGroup>

            <DsFormGroup
              label="IBAN"
              :inline-reverse="!isMobile"
              validation-name="iban"
              class="whitespace-nowrap"
            >
              <div class="w-full flex justify-end">
                <DsInput
                  v-model="form.iban"
                  placeholder=""
                  class="w-full md:w-80"
                  size="lg"
                  @focus="onIbanFocus"
                  @blur="onIbanBlur"
                />
              </div>
            </DsFormGroup>
            <DsFieldError
              v-if="ibanDetailsError"
              class="-mt-3"
            >
              {{ ibanDetailsError }}
            </DsFieldError>

            <DsFormGroup
              label="BIC"
              :inline-reverse="!isMobile"
              validation-name="bic"
              class="whitespace-nowrap"
            >
              <div class="w-full flex justify-end">
                <DsInput
                  v-model="form.bic"
                  size="lg"
                  placeholder=""
                  class="w-full md:w-80"
                  :is-loading="ibanDetailsLoading"
                  @focus="resetError('bic')"
                />
              </div>
            </DsFormGroup>

            <DsFormGroup
              label="Bank"
              :inline-reverse="!isMobile"
              validation-name="bankName"
              class="whitespace-nowrap"
            >
              <div class="w-full flex justify-end">
                <DsInput
                  v-model="form.bankName"
                  size="lg"
                  placeholder=""
                  class="w-full md:w-80"
                  :is-loading="ibanDetailsLoading"
                  @focus="resetError('bankName')"
                />
              </div>
            </DsFormGroup>

            <DsFormGroup
              label="Standard"
              :inline-reverse="!isMobile"
              class="whitespace-nowrap"
            >
              <div class="w-full flex md:justify-end py-3">
                <DsSwitch v-model="form.main" class="md:mr-[272px]" size="lg" />
              </div>
            </DsFormGroup>

            <ContractsField
              :model-value="form.contractIds"
              @show-select-contracts="showSelectContracts = !showSelectContracts"
            />
          </div>
        </DsForm>
        <SelectContracts
          v-else
          v-model="form.contractIds"
          @close="showSelectContracts = false"
        />
      </Transition>
    </div>

    <div class="flex flex-col-reverse sm:flex-row justify-end gap-3 mt-3">
      <DsButton
        variant="secondary"
        size="lg"
        @click="close"
      >
        Abbrechen
      </DsButton>
      <DsButton
        variant="primary"
        size="lg"
        :handler="() => handleCreateAccount()"
      >
        Erstellen
      </DsButton>
    </div>
  </div>
</template>

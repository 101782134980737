<script setup lang="ts">

import type { ContractData } from '@/contracts/types/types';
import { PaymentTypeOptions } from '@/contracts/types/types';
import {
  DsForm,
  DsFormGroup,
  DsInput,
  DsSelect,
  DsTextarea,
  DsAlert,
} from '@demvsystems/design-components';
import MoneyInput from '@/application/components/form-elements/MoneyInput/MoneyInput.vue';
import DateInput from '@/application/components/form-elements/DateInput/DateInput.vue';
import { toOptions } from '@/application/composables/vselect';
import { useGesellschaften } from '@/application/composables/gesellschaften';
import { useDetectMobile } from '@/application/composables/detectMobile';
import { useSparten } from '@/documents/composables/sparten';
import { computed } from 'vue';

interface Props {
  modelValue: ContractData,
  updateField: () => void,
  validationErrors?: Record<string, string[]>,
  updateForm?: boolean,
}

const props = defineProps<Props>();

const { isMobile } = useDetectMobile();
const { sparten, fetchSparten } = useSparten();
const { gesellschaften, fetchGesellschaften } = useGesellschaften();

const { isKfzSparte } = useGesellschaften();
const isKfz = computed(() => isKfzSparte(props.modelValue.sparte_id));
const gesellschaftOptions = toOptions(gesellschaften, 'name', 'id');

fetchGesellschaften();
fetchSparten();
</script>

<template>
  <DsForm :validation-errors="validationErrors">
    <div class="flex flex-col gap-4">
      <DsFormGroup
        label="Gesellschaft"
        validation-name="gesellschaft_id"
        :inline-reverse="!isMobile"
      >
        <div class="w-full flex justify-end">
          <DsSelect
            :model-value="modelValue.gesellschaft_id"
            :data="gesellschaftOptions"
            :is-loading="gesellschaftOptions.length === 0"
            placeholder=""
            size="lg"
            class="w-full md:w-80"
            @update:model-value="updateField('gesellschaft_id', $event)"
          />
        </div>
      </DsFormGroup>

      <DsFormGroup
        label="Sparte"
        validation-name="sparte_id"
        :inline-reverse="!isMobile"
      >
        <div class="w-full flex justify-end">
          <DsSelect
            :model-value="modelValue.sparte_id"
            :data="sparten"
            value-key="id"
            label-key="display_name"
            placeholder=""
            size="lg"
            class="w-full md:w-80"
            @update:model-value="updateField('sparte_id', $event)"
          >
            <template #entry="{ entry }">
              {{ entry.display_name }}
              <span
                v-if="!!entry.type"
                class="badge text-secondary"
              >
                {{ entry.type }}
              </span>
            </template>
          </DsSelect>
        </div>
      </DsFormGroup>

      <DsFormGroup
        label="Vertrags-Nr."
        validation-name="vertragsnummer"
        :inline-reverse="!isMobile"
        class="whitespace-nowrap"
      >
        <div class="w-full flex justify-end">
          <DsInput
            :model-value="modelValue.vertragsnummer"
            placeholder=""
            size="lg"
            class="w-full md:w-80"
            @update:model-value="updateField('vertragsnummer', $event)"
          />
        </div>
      </DsFormGroup>

      <DsFormGroup
        label="Beginn"
        validation-name="beginn"
        :inline-reverse="!isMobile"
      >
        <div class="w-full flex justify-end">
          <DateInput
            :model-value="modelValue.beginn"
            placeholder=""
            size="lg"
            class="w-full md:w-80"
            @update:model-value="updateField('beginn', $event)"
          />
        </div>
      </DsFormGroup>

      <DsFormGroup
        label="Ablaufdatum"
        validation-name="ablaufdatum"
        :inline-reverse="!isMobile"
      >
        <div class="w-full flex justify-end">
          <DateInput
            :model-value="modelValue.ablaufdatum"
            size="lg"
            class="w-full md:w-80"
            @update:model-value="updateField('ablaufdatum', $event)"
          />
        </div>
      </DsFormGroup>

      <DsFormGroup
        label="Beitrag"
        validation-name="beitrag"
        :inline-reverse="!isMobile"
      >
        <div class="w-full flex justify-end">
          <MoneyInput
            :model-value="modelValue.beitrag"
            size="lg"
            class="w-full md:w-80"
            :disabled="updateForm"
            @update:model-value="updateField('beitrag', $event)"
          />
        </div>
      </DsFormGroup>

      <DsFormGroup
        label="Zahlweise"
        validation-name="zahlweise"
        :inline-reverse="!isMobile"
      >
        <div class="w-full flex justify-end">
          <DsSelect
            :model-value="modelValue.zahlweise"
            :data="PaymentTypeOptions"
            placeholder=""
            size="lg"
            class="w-full md:w-80"
            @update:model-value="updateField('zahlweise', $event)"
          />
        </div>
      </DsFormGroup>

      <DsFormGroup
        v-if="isKfz"
        label="Kennzeichen"
        :inline-reverse="!isMobile"
      >
        <div class="flex justify-between md:justify-end mx-auto lg:mx-0 w-full gap-1">
          <DsInput
            :model-value="modelValue.kennzeichen.unterscheidungszeichen"
            :disabled="updateForm"
            size="lg"
            :mask="{
              mask: 'AAA',
              tokens: { A: { pattern: /[a-zA-ZäöüÄÖÜ]/, uppercase: true } },
            }"
            style="max-width: 74px"
            @update:model-value="updateField('kennzeichen.unterscheidungszeichen', $event)"
          />
          <DsInput
            :model-value="modelValue.kennzeichen.erkennungszeichen"
            :disabled="updateForm"
            size="lg"
            :mask="{
              mask: 'AA',
              tokens: { A: { pattern: /[a-zA-ZäöüÄÖÜ]/, uppercase: true } },
            }"
            style="max-width: 56px"
            @update:model-value="updateField('kennzeichen.erkennungszeichen', $event)"
          />
          <DsInput
            :model-value="modelValue.kennzeichen.erkennungsnummer"
            :disabled="updateForm"
            size="lg"
            mask="####"
            style="max-width: 78px"
            @update:model-value="updateField('kennzeichen.erkennungsnummer', $event)"
          />
          <DsSelect
            :model-value="modelValue.kennzeichen.abschliessendeszeichen"
            :disabled="updateForm"
            :data="[
              {label: 'H', value: 'H'},
              {label: 'E', value: 'E'},
            ]"
            :searchable="false"
            size="lg"
            style="min-width: 100px"
            placeholder=""
            @update:model-value="updateField('kennzeichen.abschliessendeszeichen', $event)"
          />
        </div>
      </DsFormGroup>

      <DsFormGroup
        v-if="!updateForm"
        label="Notiz für den Makler"
        :inline-reverse="!isMobile"
      >
        <DsTextarea
          :model-value="modelValue.notiz"
          placeholder="Wenn Sie möchten, können Sie eine Notiz zur Vertragsanlage hinterlassen..."
          :rows="3"
          class="w-full md:w-80"
          @update:model-value="updateField('notiz', $event)"
        />
      </DsFormGroup>
    </div>

    <DsAlert
      v-if="isKfz && updateForm"
      type="info"
      icon="circle-info"
      class="mt-5 text-left"
    >
      Kennzeichen können nicht bearbeitet werden. Bei Fehlern im Kennzeichen,
      löschen Sie den Vertrag und legen sie ihn neu an oder fragen Sie ihren Makler.
    </DsAlert>
  </DsForm>
</template>

<script setup lang="ts" generic="T extends ExternalLinkData">
import type { ActionBlockProps, ExternalLinkData } from '@/api/actions/types';
import { DsButton, NotificationVariant, useNotification } from '@demvsystems/design-components';
import { logAction } from '@/api/actions/actionsApi';
import { useGet } from "@/api/lib/integration";
import { storeToRefs } from "pinia";
import { useBaseDataStore } from "@/user/store/baseDataStore.ts";
import { openInAppBrowser } from "@/application/plugins/inAppBrowser.ts";

const props = defineProps<ActionBlockProps<T>>();

async function logExternalLink() {
  await logAction({
    actionId: props.actionId,
    context: props.baustein.type,
    data: props.baustein.data,
  });
}

const { isBroker } = storeToRefs(useBaseDataStore());

async function onClick() {
  const data = await useGet<void, { url: string }>(props.baustein.data.url)();
  if (!(data instanceof Error)) {
    openInAppBrowser(data.url).then();
    logExternalLink();
  } else {
    useNotification().show(
      'Unerwarteter Fehler beim Öffnen des Links.',
      NotificationVariant.Error,
    );
  }
}
</script>

<template>
  <div>
    <DsButton
      variant="secondary"
      external
      :disabled="isBroker"
      class="my-3 mr-auto"
      @click="onClick"
    >
      {{ baustein.data.label }}
    </DsButton>
  </div>
</template>

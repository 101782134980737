<template>
  <DetailViewContainer>
    <template #title>
      <h3 class="text-gray-900">
        Depot | {{ sparte }}
      </h3>
    </template>

    <template #header>
      <div class="flex items-center">
        <CompanyIcon :id="depot.company.id" />

        <div
          class="ml-2.5 text-sm text-muted"
          v-text="depot.company.name"
        />
      </div>
    </template>

    <template #main>
      <DepotDetailsTable :depot="props.depot" />
    </template>

    <template #extra>
      <ShallowCardCollapsible
        :title="`Fonds (${depot.depotFonds?.length ?? 0})`"
        max-height="29rem"
        :disabled="!hasDepotFonds"
        :open="hasDepotFonds && !isMobile"
      >
        <DepotFonds :depot-fonds="depot.depotFonds!" />
      </ShallowCardCollapsible>
    </template>
  </DetailViewContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import type { Depot } from '@/api/contracts/types';
import CompanyIcon from '@/application/components/CompanyIcon.vue';
import ShallowCardCollapsible from '@/application/components/ShallowCardCollapsible.vue';
import { useDetectMobile } from '@/application/composables/detectMobile';
import DepotFonds from '@/contracts/components/DepotFonds.vue';
import DetailViewContainer from '@/contracts/components/DetailViewContainer.vue';
import DepotDetailsTable from '@/contracts/components/DepotDetailsTable';

type Props = {
  depot: Depot
}
const props = defineProps<Props>();
const { isMobile } = useDetectMobile();
const sparte = computed(() => props.depot.product?.name.replace('->', ' › ') || '');
const hasDepotFonds = computed(() => props.depot.depotFonds && props.depot.depotFonds.length > 0);
</script>

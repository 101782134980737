<script setup lang="ts">
import { onUnmounted, onMounted, provide, ref } from 'vue';
import { DsNotification } from "@demvsystems/design-components";
import OverlayAppUpdate from "@/application/components/OverlayAppUpdate.vue";
import OverlayLiveUpdate from "@/application/components/OverlayLiveUpdate.vue";
import { usePolling } from "@/application/composables/usePolling";
import { useElementSize, useScroll } from '@vueuse/core'
import OverlayLockscreen from "@/application/components/OverlayLockscreen.vue";

const header = ref<HTMLElement | null>(null);
const footer = ref<HTMLElement | null>(null);

const main = ref();
const { y } = useScroll(main, { throttle: 50 });
const { height: headerHeight } = useElementSize(header);
const { height: footerHeight } = useElementSize(footer);

usePolling();

provide('headerHeight', headerHeight);
provide('footerHeight', footerHeight);
provide('y', y);

onMounted(() => {
  window.document.body.classList.add('mounted');
});

onUnmounted(() => {
  window.document.body.classList.remove('mounted');
})
</script>

<template>
  <header id="app-header" ref="header" />
  <main id="app-main" ref="main">
    <div id="app-page-head" />
    <div id="app-page-content">
      <RouterView />
    </div>
    <div id="app-page-footer" />
  </main>
  <footer id="app-footer" ref="footer" />
  <KeepAlive>
    <DsNotification target="#app-notifications" class="p-safe notification-fix" />
  </KeepAlive>
  <Teleport to="#app-overlay">
    <OverlayAppUpdate />
    <OverlayLiveUpdate />
  </Teleport>
  <Teleport to="#app-lockscreen">
    <OverlayLockscreen />
  </Teleport>
</template>

<style>
#app {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  contain: layout style size;
  z-index: 1;
}

#app-header {
  z-index: 2;
  width: 100%;
  flex: none;
}

#app-footer {
  z-index: 2;
  background: white;
  flex: none;
  width: 100%;
  display: block;
}

#app-main {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

#app-page-head {
  flex: none;
  width: 100%;
  display: block;
}

#app-page-footer {
  flex: none;
  display: block;
  width: 100%;
}

#app-page-content {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

#app-page-head {
  flex: none;
  display: block;
  width: 100%;
}

#app-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
  z-index: 10;
}

#app-notifications {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  pointer-events: none;
  z-index: 1001;
}

#app-lockscreen {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow: hidden;
  pointer-events: none;
  z-index: 99999;
}

.notification-fix {
  bottom: auto !important;
  top: 1rem !important;
}
</style>

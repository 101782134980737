import type { Api, Path, PendingRequest, RequestOptions } from "@/api/lib/types";
import type { Ref } from "vue";
import { ref } from "vue";
import { cloneDeep } from "lodash";
import { ThrottleError, ValidationError } from "@/api/lib/errors";
import { makeRequest } from "@/api/lib/request";
import { captureException } from "@sentry/vue";
import { set } from "lodash-es";

export function useGet<Data, Res>(url: string, options: RequestOptions = {}): Api<Data, Res> {
  return (data?: Data) => makeRequest<Data, Res>('get', url, options, data);
}
export function usePost<Data, Res>(url: string, options: RequestOptions = {}): Api<Data, Res> {
  return (data?: Data) => makeRequest<Data, Res>('post', url, options, data);
}
export function usePut<Data, Res>(url: string, options: RequestOptions = {}): Api<Data, Res> {
  return (data?: Data) => makeRequest<Data, Res>('put', url, options, data);
}
export function usePatch<Data, Res>(url: string, options: RequestOptions = {}): Api<Data, Res> {
  return (data?: Data) => makeRequest<Data, Res>('patch', url, options, data);
}
export function useDelete<Data, Res>(url: string, options: RequestOptions = {}): Api<Data, Res> {
  return (data?: Data) => makeRequest<Data, Res>('delete', url, options, data);
}

export async function call<Res>(
  request: PendingRequest<Res>,
  ok?: (data: Res) => void | Promise<void>,
  fail?: (error: Error) => void | Promise<void>,
): Promise<boolean> {
  const response = await request;
  if (response instanceof Error) {
    await fail?.(response);
    return false;
  } else {
    await ok?.(response as Res);
    return true;
  }
}

export async function throwableCall<Res>(request: PendingRequest<Res>): Promise<Res> {
  const response = await request;
  if(response instanceof Error) {
    throw response;
  } else {
    return response;
  }
}

export function useRequest<Data, Res>(
  endpoint: Api<Data, Res>,
  ok?: (data: Res) => void | Promise<void>,
  fail?: (error: Error) => void | Promise<void>
){
  const loading = ref(false);

  async function makeCall(data?: Data): Promise<boolean> {
    loading.value = true;
    const result = await call(endpoint(data), ok, fail);
    loading.value = false;
    return result;
  }

  return { loading, call: makeCall };
}

export function useForm<Data extends Record<string, unknown>, Res>(
  endpoint: Api<Data, Res>,
  initForm: Data,
  ok?: (data: Res) => void | Promise<void>,
  unexpectedFail?: (error: unknown) => void | Promise<void>,
  nestedFormKey?: string,
) {
  const initState = cloneDeep(initForm);
  const form = ref(cloneDeep(initState)) as Ref<Data> ;
  const errorMsg = ref('');
  const errors = ref<Record<string, string[]>>({});

  function onError(error: unknown) {
    if (error instanceof ValidationError) {
      errors.value = error.errors;
      if (Object.keys(error.errors).length === 0) {
        errorMsg.value = error.message;
      }
    } else if (error instanceof ThrottleError) {
      errorMsg.value = 'Sie haben zu viele Anmeldeversuche. Warten sie wenige Minuten, bevor Sie es erneut probieren.';
    } else {
      // @ts-ignore
      errorMsg.value = 'Ein unerwarteter Fehler ist aufgetreten.';
      captureException(error);
      unexpectedFail?.(error);
    }
  }

  const { loading, call: makeCall } = useRequest(endpoint, ok, onError);

  function clearErrors() {
    errorMsg.value = '';
    errors.value = {};
  }

  function reset() {
    clearErrors();
    form.value = cloneDeep(initState);
  }

  async function submit(): Promise<boolean> {
    clearErrors();
    return await makeCall(form.value);
  }

  function updateField(field: Path<Data>, value: unknown) {
    set(form.value, nestedFormKey ? `${nestedFormKey}.${field}` : field, value);
    delete errors.value[field];
  }

  function updateForm(data: Data) {
    form.value = cloneDeep(data);
  }

  return { form, loading, errors, submit, reset, errorMsg, updateField, updateForm };
}

export function useMultipleRequests<Data, Res>(
  endpoints: Array<[Api<Data, Res>, Data?]>,
  ok?: (responses: Res[]) => void | Promise<void>,
  fail?: (error: Error, index: number) => void | Promise<void>
) {

  const loading = ref(false);
  const results: Ref<Res[]> = ref([]);
  const errors: Ref<Error[]> = ref<Error[]>([]);

  async function makeCalls() {
    loading.value = true;
    results.value = [];
    errors.value = [];

    try {
      const promises = endpoints.map(([endpoint, data]) =>
        endpoint(data).then(
          response => ({ success: true as const, response }),
          error => ({ success: false as const, error })
        )
      );

      const outcomes = await Promise.all(promises);

      let allSuccessful = true;
      outcomes.forEach((outcome, index) => {
        if (outcome.success) {
          results.value.push(outcome.response as Res);
        } else {
          errors.value[index] = outcome.error;
          allSuccessful = false;
          fail?.(outcome.error, index);
        }
      });

      if (allSuccessful) {
        ok?.(results.value);
      }
    } catch (error) {
      console.error("Unexpected error in makeCalls:", error);
    } finally {
      loading.value = false;
    }
  }

  return { makeCalls, loading, results, errors };
}

export async function getBase64Image(url: string, isAbsoluteUrl = true): Promise<string|null> {
  const resp = await useGet<void, Blob>(url, { accept: 'image/jpg', isAbsoluteUrl })();
  if (resp instanceof Error) return null;

  const blob = resp;
  const reader = new FileReader();
  await new Promise((resolve) => {
    reader.onload = resolve;
    reader.onerror = () => resolve(null);
    reader.readAsDataURL(blob);
  });

  return reader.result as string|null;
}

import { computed } from 'vue';
import type { Ref } from 'vue';
import type { Contract, ContractMeta } from '@/api/contracts/types';
import { formatDate } from '@/application/utils/date';
import ContractPayment from '@/contracts/components/ContractPayment.vue';
import DetailValue from '@/contracts/components/DetailValue.vue';
import Coinsured from '@/contracts/components/Coinsured.vue';
import LicensePlate from '@/contracts/components/LicensePlate.vue';
import ContractStatus from '@/contracts/components/ContractStatus.vue';

const isValidMeta = (meta: Contract['meta']): meta is ContractMeta => !Array.isArray(meta);

export function useContractDetails(contract: Ref<Contract>) {
  const contractDetails = computed(() => {
    return [
      { label: 'Status', props: { status: contract.value.status }, component: ContractStatus, if: true },
      {
        label: 'Vertragsnummer',
        props: { value: contract.value.number },
        component: DetailValue,
        if: !!contract.value.number
      },
      {
        label: 'Kosten',
        props: {
          amount: contract.value.payment?.zahlbeitrag,
          interval: contract.value.payment?.interval,
          inline: false,
        },
        component: ContractPayment,
        if: !!contract.value.payment
      },
      {
        label: 'Beginn',
        props: { value: contract.value.term?.start ? formatDate(contract.value.term.start) : '' },
        component: DetailValue,
        if: !!contract.value.term?.start
      },
      {
        label: 'Ablaufdatum',
        props: { value: contract.value.term?.end ? formatDate(contract.value.term.end) : '' },
        component: DetailValue,
        if: !!contract.value.term?.end
      },
      {
        label: 'Risikoort',
        props: {
          value: contract.value.risikoort?.displayText,
        },
        component: DetailValue,
        if: !!contract.value.risikoort
      },
      {
        label: 'Mitversichert bei',
        props: { value: `${contract.value.owner?.firstName || ''} ${contract.value.owner?.lastName}` },
        component: DetailValue,
        if: !!contract.value.owner
      },
      {
        label: 'Mitversicherte Personen',
        props: { coinsuredPersons: contract.value.coinsured },
        component: Coinsured,
        if: contract.value.coinsured.length > 0,
      },
      {
        label: 'Kennzeichen',
        props: { licensePlate: contract.value.meta?.kfz!.licensePlate },
        component: LicensePlate,
        if: isValidMeta(contract.value.meta) && contract.value.meta?.kfz
      },
    ];
  });

  return { contractDetails };
}

<script setup lang="ts">

import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core'
import { autoUpdate, flip, offset, type Placement, shift, useFloating } from '@floating-ui/vue';
import { DsButton } from '@demvsystems/design-components';

interface Props { placement?: Placement, icon?: string }

const { placement = 'bottom-end', icon = 'ellipsis' } = defineProps<Props>();

const show = ref(false)
const target = ref(null)
const popover = ref(null);

const { floatingStyles } = useFloating(target, popover, {
  placement,
  middleware: [offset(10), flip(), shift({ padding: 16 })],
  whileElementsMounted: autoUpdate,
});

onClickOutside(target, () => show.value = false)
</script>

<template>
  <div ref="target">
    <slot name="button" :open="show" :toggle="() => show = !show">
      <DsButton variant="clear" :icon="icon" @click="() => show = !show" />
    </slot>
    <div
      v-if="show"
      ref="popover"
      class="z-[1000] p-4 space-y-3 rounded shadow-lg bg-white border border-gray-100 overflow-y-auto"
      :style="floatingStyles"
    >
      <slot />
    </div>
  </div>
</template>

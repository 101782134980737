<script setup lang="ts">
import { useHandlungStore } from '@/actions/store/actionStore';
import { storeToRefs } from 'pinia';
import { Finanzmanager } from '@/application/router/types/types';
import ActionPageSkeleton from '@/actions/components/ActionPageSkeleton.vue';
import DetailPageLink from '@/application/components/DetailPageLink.vue';
import { DsAlert, DsButton, DsIcon } from '@demvsystems/design-components';
import { validUntilLabel } from '@/actions/utils/dateFormat';
import PageContentWrapper from '@/application/layouts/ContentWrapper/PageContentWrapper.vue';

const { fetchActions } = useHandlungStore();
const { initializing, errorMsg, actionsByState } = storeToRefs(useHandlungStore());
</script>

<template>
  <PageContentWrapper>
    <div class="hidden md:block text-center">
      <h1 class="text-center text-2xl md:text-3xl lg:text-4xl">Inbox</h1>
    </div>

    <div class="flex flex-col h-full md:my-5">
      <ActionPageSkeleton v-if="initializing" />

      <div v-else-if="errorMsg">
        <DsAlert
          type="error"
          label="Es gab einen Fehler."
          class="mx-auto my-10"
        >
          Bitte laden Sie die Seite neu oder versuchen es später noch einmal!
        </DsAlert>

        <DsButton variant="secondary" :handler="fetchActions">Ernaut laden</DsButton>
      </div>

      <div v-else-if="actionsByState.open?.length|| actionsByState.done?.length">
        <ul class="flex flex-col gap-2">
          <li
            v-for="({ id, title, expiry_date }) in actionsByState.open"
            :key="id"
          >
            <DetailPageLink
              :title="title"
              :to="{ name: Finanzmanager.HANDLUNG, params: { id } }"
            >
              <template #meta>
                <span class="font-light text-gray-500 text-xs flex-shrink-0 md:ml-auto">
                  noch gültig für
                  <span class="font-medium text-gray-900">
                    {{ validUntilLabel(expiry_date) }}
                  </span>
                </span>
              </template>
            </DetailPageLink>
          </li>
        </ul>

        <div v-if="actionsByState.done?.length" class="mt-10 mb-2">
          <span class="font-light text-sm text-gray-500">kürzlich abgeschlossen:</span>
        </div>

        <ul class="flex flex-col gap-2">
          <li
            v-for="({ id, title }) in actionsByState.done"
            :key="id"
          >
            <DetailPageLink
              :title="title"
              :to="{ name: Finanzmanager.HANDLUNG, params: { id } }"
            >
              <template #title>
                <div class="flex items-center gap-3">
                  <DsIcon
                    name="check"
                    size="lg"
                    variant="regular"
                    class="bg-green-100 p-1.5 h-3 w-3 text-green-700 rounded-full ml-auto"
                  />
                  <span class="text-lg text-gray-900">{{ title }}</span>
                </div>
              </template>
            </DetailPageLink>
          </li>
        </ul>
      </div>

      <div
        v-else
        class="flex flex-col flex-grow justify-center items-center gap-5 p-5"
      >
        <DsIcon
          name="empty-set"
          variant="light"
          size="xl"
          class="text-gray-500 bg-gray-100 rounded-full p-3"
        />
        <p class="m-0 text-center lead text-muted">
          Es liegen zur Zeit keine offenen Handlungen für Sie vor.
        </p>
      </div>
    </div>
  </PageContentWrapper>
</template>

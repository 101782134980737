<template>
  <div>
    <p>
      Die nachfolgende Datenschutzerklärung gilt für die Nutzung unseres Online-Angebots
      https://meine-finanzen.digital/ (nachfolgend „Website“).
    </p>
    <p>
      Wir messen dem Datenschutz große Bedeutung bei.
      Die Erhebung und Verarbeitung Ihrer personenbezogenen Daten geschieht unter Beachtung der geltenden
      datenschutzrechtlichen Vorschriften,
      insbesondere der Datenschutzgrundverordnung (DSGVO).
    </p>

    <h3>1. Verantwortlicher</h3>
    <p>
      Verantwortlicher für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten im Sinne von Art. 4 Nr.
      7 DSGVO ist
    </p>
    <strong>DEMV Deutscher Maklerverbund GmbH</strong>
    <p>
      Dammtorwall 7a <br>
      20354 Hamburg
    </p>
    <p>
      Telefon: <a href="tel:+494046093299">+49 (0)40 460 93 299</a> <br>
      E-Mail: <a href="mailto:info@demv.de">info@demv.de</a>
    </p>
    <p>
      Sofern Sie der Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch uns nach Maßgabe dieser
      Datenschutzbestimmungen insgesamt oder für einzelne Maßnahmen widersprechen wollen, können Sie Ihren
      Widerspruch an den Verantwortlichen richten.
    </p>
    <p>Sie können diese Datenschutzerklärung jederzeit speichern und ausdrucken.</p>

    <h3>2. Allgemeine Zwecke der Verarbeitung</h3>
    <p>
      Wir verwenden personenbezogene Daten zum Zweck des Betriebs der Website und zur Bearbeitung Ihrer Anfragen
      in einem geschäftlichen Zusammenhang, sowie zur Auswertung des Nutzerverhaltens
    </p>

    <h3>3. Welche Daten wir verwenden und warum</h3>
    <h4>3.1 Hosting</h4>
    <p>
      Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen:
      Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste,
      Sicherheitsleistungen sowie technische Wartungsleistungen,
      die wir zum Zweck des Betriebs der Website einsetzen.
    </p>
    <p>
      Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten,
      Nutzungsdaten,
      Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieser Website auf Grundlage unserer
      berechtigten Interessen
      an einer effizienten und sicheren Zurverfügungstellung unserer Website gem. Art. 6 Abs. 1 S. 1 f) DSGVO i.V.m.
      Art. 28 DSGVO.
    </p>
    <h4>3.2 Zugriffsdaten</h4>
    <p>
      Wir sammeln Informationen über Sie, wenn Sie diese Website nutzen. Wir erfassen automatisch Informationen über
      Ihr Nutzungsverhalten und Ihre Interaktion
      mit uns und registrieren Daten zu Ihrem Computer oder Mobilgerät. Wir erheben, speichern und nutzen Daten über
      jeden Zugriff
      auf unsere Website (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören:
    </p>
    <ul class="pl-4 list-disc">
      <li>Name und URL der abgerufenen Datei</li>
      <li>Datum und Uhrzeit des Abrufs</li>
      <li>übertragene Datenmenge</li>
      <li>Meldung über erfolgreichen Abruf (HTTP response code)</li>
      <li>Browsertyp und Browserversion</li>
      <li>Betriebssystem</li>
      <li>Referer URL (d.h. die zuvor besuchte Seite)</li>
      <li>Websites, die vom System des Nutzers über unsere Website aufgerufen werden</li>
      <li>Internet-Service-Provider des Nutzers</li>
      <li>IP-Adresse und der anfragende Provider</li>
    </ul>
    <br>
    <p>
      Wir nutzen diese Protokolldaten ohne Zuordnung zu Ihrer Person oder sonstiger Profilerstellung für statistische
      Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung unserer Website, aber auch zur anonymen
      Erfassung der Anzahl der Besucher auf
      unserer Website (traffic) sowie zum Umfang und zur Art der Nutzung unserer Website und Dienste, ebenso zu
      Abrechnungszwecken,
      um die Anzahl der von Kooperationspartnern erhaltenen Clicks zu messen. Aufgrund dieser Informationen können wir
      personalisierte und standortbezogene Inhalte zur Verfügung stellen und den Datenverkehr analysieren, Fehler
      suchen und beheben und unsere Dienste verbessern.
    </p>
    <p>Hierin liegt auch unser berechtigtes Interesse gemäß Art 6 Abs. 1 S. 1 f) DSGVO.</p>
    <p>
      Wir behalten uns vor, die Protokolldaten nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der
      berechtigte Verdacht einer rechtswidrigen Nutzung besteht. IP-Adressen speichern wir auch dann, wenn wir den
      konkreten Verdacht einer
      Straftat im Zusammenhang mit der Nutzung unserer Website haben.
    </p>
    <h4>3.3 Cookies</h4>
    <p>
      Wir verwenden sogenannte Session-Cookies, um unsere Website zu optimieren. Ein Session-Cookie ist eine kleine
      Textdatei, die von den jeweiligen Servern beim Besuch einer Internetseite verschickt und auf Ihrer Festplatte
      zwischengespeichert wird. Diese Datei als solche enthält eine sogenannte Session-ID, mit welcher sich
      verschiedene Anfragen Ihres Browsers
      der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere
      Website zurückkehren. Diese Cookies werden
      gelöscht, nachdem Sie Ihren Browser schließen. Sie dienen z. B. dazu, die erneute Einblendung des Cookie-Banners
      zu
      unterbinden.
    </p>
    <p>
      Wir verwenden in geringem Umfang auch persistente Cookies (ebenfalls kleine Textdateien, die auf Ihrem Endgerät
      abgelegt werden), die auf Ihrem Endgerät verbleiben und es uns ermöglichen, Ihren Browser beim nächsten Besuch
      wiederzuerkennen.
      Diese Cookies werden auf Ihrer Festplatte gespeichert und löschen sich nach der vorgegebenen Zeit von allein.
      Ihre Lebensdauer beträgt 1 Monat bis 10 Jahre. So können wir Ihnen unser Angebot nutzerfreundlicher,
      effektiver und sicherer präsentieren und Ihnen beispielsweise speziell auf Ihre Interessen abgestimmte
      Informationen auf der Seite anzeigen.
    </p>
    <p>
      Unser berechtigtes Interesse an der Nutzung der Cookies gemäß Art 6 Abs. 1 S. 1 f) DSGVO liegt darin,
      unsere Website nutzerfreundlicher, effektiver und sicherer zu machen.
    </p>
    <p>In den Cookies werden etwa folgende Daten und Informationen gespeichert:</p>
    <ul class="pl-4 list-disc">
      <li>Log-In-Informationen</li>
      <li>Spracheinstellungen</li>
      <li>eingegebene Suchbegriffe</li>
      <li>
        Informationen über die Anzahl der Aufrufe unserer Website sowie Nutzung einzelner Funktionen unseres
        Internetauftritts.
      </li>
    </ul>
    <br>
    <p>
      Bei Aktivierung des Cookies wird diesem eine Identifikationsnummer zugewiesen und eine Zuordnung Ihrer
      personenbezogenen Daten zu dieser Identifikationsnummer wird nicht vorgenommen. Ihr Name, Ihre IP-Adresse oder
      ähnliche Daten, die eine Zuordnung des Cookies zu Ihnen ermöglichen würden, werden nicht in den Cookie
      eingelegt. Auf Basis der Cookie-Technologie erhalten wir lediglich pseudonymisierte Informationen.
    </p>
    <p>
      Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies vorab informiert werden und im
      Einzelfall entscheiden können, ob Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschließen,
      oder dass Cookies komplett verhindert werden. Dadurch kann die Funktionalität der Website eingeschränkt
      werden.
    </p>
    <h4>3.4 Daten zur Erfüllung unserer vertraglichen Pflichten</h4>
    <p>
      Wir verarbeiten personenbezogene Daten, die wir zur Erfüllung unserer vertraglichen Pflichten benötigen,
      etwa Name, Adresse, E-Mail-Adresse. Die Erhebung dieser Daten ist für die Erbringung unseres Services
      erforderlich.
    </p>
    <p>
      Die Löschung der Daten erfolgt nach Ablauf der Gewährleistungsfristen und gesetzlicher Aufbewahrungsfristen
    </p>
    <p>
      Die Rechtgrundlage für die Verarbeitung dieser Daten ist Art. 6 Abs. 1 S. 1 b) DSGVO, denn diese
      Daten werden benötigt, damit wir unsere vertraglichen Pflichten Ihnen gegenüber erfüllen können.
    </p>
    <h4>3.5 Kontaktaufnahme</h4>
    <p>
      Wenn Sie mit uns in Kontakt treten (z. B. per Kontaktformular oder E-Mail), verarbeiten wir Ihre Angaben zur
      Bearbeitung der Anfrage sowie für den Fall, dass Anschlussfragen entstehen.
    </p>
    <p>
      Erfolgt die Datenverarbeitung zur Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen,
      bzw., wenn Sie bereits unser Kunde sind, zur Durchführung des Vertrages, ist Rechtsgrundlage für diese
      Datenverarbeitung Art. 6 Abs. 1 S. 1 b) DSGVO.
    </p>
    <p>
      Weitere personenbezogene Daten verarbeiten wir nur, wenn Sie dazu einwilligen (Art. 6 Abs. 1 S. 1 a) DSGVO) oder
      wir ein berechtigtes Interesse an der Verarbeitung Ihrer Daten haben (Art. 6 Abs. 1 S. 1 f) DSGVO). Ein
      berechtigtes Interesse liegt z. B. darin, auf Ihre E-Mail zu antworten.
    </p>
    <h4>3.6 Nutzerkonto</h4>
    <p>
      Im Rahmen der Zusammenarbeit mit Ihrem Makler kann dieser für Sie ein Nutzerkonto anlegen. Beim späteren
      Einloggen werden nur Ihre Email und das von Ihnen gewählte Passwort benötigt.
    </p>
    <p>
      Für die Registrierung werden die von Ihnen bereitgestellten Stammdaten verarbeitet (z. B. Name, Adresse),
      Kommunikationsdaten (z. B. E-Mail-Adresse sowie Zugangsdaten (Benutzername u. Passwort).
    </p>
    <p>
      Um Ihre ordnungsgemäße Anmeldung sicherzustellen und unautorisierte Anmeldungen durch Dritte zu verhindern,
      erhalten Sie nach Ihrer Registrierung eine Willkommensnachricht.
    </p>
    <p>
      Rechtgrundlage für die Verarbeitung dieser Daten ist Ihre Einwilligung gemäß Art. 6 Abs. 1 S. 1 a) DSGVO. Eine
      erteilte Einwilligung kann jederzeit, mit Wirkung für die Zukunft, widerrufen werden.
    </p>
    <p>
      Als registrierter Benutzer können Sie unsere Services in Anspruch nehmen, wie z.B. eine Ermittlung Ihres
      Versicherungsbedarfs. Hierzu werden über ein Formular bestimmte Angaben zu Ihrer Lebenssituation abgefragt.
      Welche Daten als Pflichtfelder erhoben werden, ist aus dem Fragenkatalog ersichtlich. Rechtsgrundlage für die
      Verarbeitung der Daten ist unser berechtigtes Interesse gemäß Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes
      Interesse liegt in der Ermittlung Ihres Bedarfs und entsprechender Vermittlung. Um Ihren Bedarf besser auf ihre
      individuelle Situation abstimmen zu können, können Sie weitere freiwillige Angaben machen. Rechtgrundlage für
      die Verarbeitung dieser Daten ist Ihre Einwilligung gemäß Art. 6 Abs. 1 S. 1 a) DSGVO. Eine erteilte
      Einwilligung kann jederzeit, mit Wirkung für die Zukunft, widerrufen werden.
    </p>
    <p>
      Zusätzlich besteht die Möglichkeit, bereits bestehende Versicherungsverhältnisse einzupflegen und Dokumente
      hochzuladen. Diese Funktion können Sie nutzen, um eine Gesamtübersicht Ihrer bestehenden Versicherungen zu
      erhalten. Rechtgrundlage für die Verarbeitung dieser Daten ist Ihre Einwilligung gemäß Art. 6 Abs. 1 S. 1 a)
      DSGVO. Eine erteilte Einwilligung kann jederzeit, mit Wirkung für die Zukunft, widerrufen
    </p>
    <p>
      Sie können ein einmal angelegtes Nutzerkonto jederzeit löschen über „Meine Daten“. Wir werden dann Ihre
      gespeicherten personenbezogenen Daten für den Zugriff auf meine-finanzen deaktivieren, soweit wir diese nicht
      noch zur Abwicklung von Bestellungen oder aufgrund gesetzlicher Aufbewahrungspflichten speichern müssen.
    </p>

    <h3>4. Informationen zu eingesetzten Dienstleistern</h3>
    <p>
      Zum Betrieb dieser Website binden wir externe Dienste ein, um unsere Website nutzerfreundlicher,
      effektiver und sicherer zu machen. Hierin liegt unser berechtigtes Interesse gemäß Art 6 Abs. 1 S. 1 f)
      DSGVO.
    </p>
    <h4>4.1 Amazon Web Services</h4>
    <p>
      Der von uns in Anspruch genommene Dienst Amazon Web Services („AWS“) der Amazon Web Services, Inc., 410
      Terry Avenue North, Seattle WA 98109, USA, dient der Zurverfügungstellung der folgenden Leistungen:
      Hosting der Datenbank, die wir zum Zweck des Betriebs der Website einsetzen.
    </p>
    <p>
      Hierbei verarbeiten wir, bzw. Amazon Web Services, Inc., Bestandsdaten, Kontaktdaten, Inhaltsdaten,
      Vertragsdaten, Nutzungsdaten, Meta-
      und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieser Website auf Grundlage unserer
      berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung unserer Website gem.
      Art. 6 Abs. 1 S. 1 f) DSGVO i.V.m. Art. 28 DSGVO.
    </p>
    <p>
      Amazon hat sich dem zwischen der Europäischen Union und
      den USA geschlossenen Data Privacy Framework unterworfen und sich zertifiziert. Dadurch verpflichtet sich
      Amazon, die Standards und Vorschriften des europäischen Datenschutzrechts einzuhalten. Nähere
      Informationen können Sie dem nachfolgend verlinkten Eintrag entnehmen:
    </p>
    <p>
      https://www.dataprivacyframework.gov/s/
    </p>
    <p>
      Nähere Informationen zu AWS und zum Datenschutz finden Sie unter
      https://aws.amazon.com/de/compliance/eu-data-protection/
      sowie unter https://aws.amazon.com/de/privacy/.
    </p>
    <h4>4.2 Cookie Consent Manager CCM19</h4>
    <p>
      Zur Steuerung von Cookies und Einholung erforderlicher Einwilligungen, nutzen wir auf unserer Website den
      Cookie Consent Manager CCM19 des Anbieters Papoo Software &amp; Media GmbH, Auguststr. 4, 53229 Bonn. Über
      dieses Tool können Sie Cookies zulassen bzw. verweigern.
    </p>
    <p>
      Wir verwenden das Consent Management Tool auf Grundlage von von Art. 6 Abs 1. S. 1 c) DSGVO. Die
      Verarbeitung dieser Daten ist erforderlich, um erteilte Einwilligungen nachweisen zu können.
    </p>
    <p>
      Informationen zur Verarbeitung der übertragenen Daten finden Sie
      hier: https://www.ccm19.de/datenschutzerklaerung.html
    </p>
    <h4>4.3 DEMV Systems</h4>
    <p>
      Zur Bereitstellung unseres Bedarfsrechners setzen wir den Dienstleister DEMV Systems GmbH, Dammtorwall 7a , 20354
      Hamburg, ein. DEMV Systems wird für die Entwicklung der verarbeitenden Software Professional Works und das
      Hosting der Daten eingesetzt.
    </p>
    <p>
      Der Einsatz der DEMV Systems erfolgt auf Grundlage von von Art. 6 Abs 1. S. 1 f) DSGVO. Unser berechtigtes
      Interesse liegt in der fehlerfreien Bereitstellung der Anwendung.
    </p>
    <p>
      Nähere Informationen zum Datenschutz erhalten Sie hier:
      <a href="https://demv-systems.de/rechtliche-hinweise">https://demv-systems.de/rechtliche-hinweise</a>
    </p>


    <h3>5. Tracking</h3>
    <h4>5.1 Matomo</h4>
    <p>
      Zur Analyse und Auswertung der Nutzung unserer Website nutzen wir das Open-Source Tool Matomo des Anbieters
      InnoCraft Ltd, 7 Waterloo Quay, PO Box 625, 6140 Wellington, New Zealand. Die Software setzt hierfür einen
      Cookie in Ihrem Browser. Beim Aufruf von verschiedenen Seiten unserer werden folgende Daten erfasst:
    </p>
    <ul>
      <li>Zwei Bytes der IP-Adresse des aufrufenden Systems des Nutzers (anonymisierte IP-Adresse)</li>
      <li>Die aufgerufene Website</li>
      <li>Die Website, von der der Nutzer auf die aufgerufene Webseite gelangt ist (Referrer)</li>
      <li>Die Unterseiten, die von der aufgerufenen Webseite aus aufgerufen werden</li>
      <li>Die Verweildauer auf der Webseite</li>
      <li>Die Häufigkeit des Aufrufs der Webseite</li>
    </ul>
    <p>
      Die Daten werden durch InnoCraft Ltd, 7 Waterloo Quay, PO Box 625, 6140 Wellington, New Zealand in der
      Matomo Cloud gehostet. Die Server befinden sich ausschließlich innerhalb der EU/EWR.
    </p>
    <p>
      Wir haben mit der InnoCraft Ltd einen Auftragsverarbeitungsvertrag geschlossen, der den Anforderungen
      von Art. 28 DSGVO entspricht.
    </p>
    <p>
      Wir verarbeiten Ihre Daten auf Grundlage Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs.
      1 TTDSG. Eine erteilte Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden.
    </p>
    <p>
      Die anonymisierten Daten werden in regelmäßigen Abständen gelöscht. Weitere Hinweise finden Sie in der
      Datenschutzerklärung: https://matomo.org/matomo-cloud-privacy-policy/
    </p>

    <h4>5.2 Google Analytics</h4>
    <p>
      Zur Analyse und Auswertung der Nutzung unserer Website nutzen wir Google Analytics um das allgemeine
      Nutzungserlebnis und einzelne Funktionen fortlaufend zu verbessern. Google Analytics verwendet Cookies, die eine
      Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre
      Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort
      gespeichert. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung
      der Website auszuwerten, um Reports über die Website-Aktivitäten zusammenzustellen und um weitere mit der
      Website-Nutzung und der Internetnutzug verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.
    </p>

    <p>
      Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern;
      wir weisen jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
      vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und
      auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
      Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plug-in herunterladen
      und installieren: https://tools.google.com/dlpage/gaoptout?hl=de
    </p>

    <p>
      Google Analytics wird auf dieser Website mit IP-Anonymisierung verwendet. Dadurch werden IP-Adressen gekürzt
      weiterverarbeitet und eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie erhobenen
      Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit
      umgehend gelöscht.
    </p>

    <p>
      Wir verarbeiten Ihre Daten auf Grundlage Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a) DSGVO.
      Eine erteilte Einwilligung kann jederzeit über das Cookie Consent Tool mit Wirkung für die Zukunft widerrufen werden.
    </p>

    <p>Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland</p>

    <p>Weitere Hinweise finden Sie in der Datenschutzerklärung unter: https://policies.google.com/privacy</p>

    <h4>5.3 Google Tag Manager</h4>
    <p>
      Der Google Tag Manager ist eine Lösung, mit der wir sog. Website-Tags über eine Oberfläche verwalten können. Der
      Tag Manager selbst erstellt keine Profile der Nutzer und es werden keine personenbezogene Daten erfasst und
      gespeichert. Google erfährt lediglich die IP-Adresse des Nutzers, was notwendig ist, um den Google Tag Manager
      auszuführen.
    </p>

    <p>
      Wir verarbeiten Ihre Daten auf Grundlage Ihrer Einwilligung gem. Art. 6 Abs. 1 lit. a) DSGVO.
      Eine erteilte Einwilligung kann jederzeit über das Cookie Consent Tool mit Wirkung für die Zukunft widerrufen werden.
    </p>

    <p>Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland</p>

    <p>Weitere Hinweise finden Sie in der Datenschutzerklärung unter: https://policies.google.com/privacy</p>

    <h3>6. Speicherdauer</h3>
    <p>
      Sofern nicht spezifisch angegeben speichern wir personenbezogene Daten nur so lange, wie dies zur Erfüllung der
      verfolgten Zwecke notwendig ist.
    </p>
    <p>
      In einigen Fällen sieht der Gesetzgeber die Aufbewahrung von personenbezogenen Daten vor, etwa im Steuer- oder
      Handelsrecht. In diesen Fällen werden die Daten von uns lediglich für diese gesetzlichen Zwecke weiter
      gespeichert, aber nicht anderweitig verarbeitet und nach Ablauf der gesetzlichen Aufbewahrungsfrist
      gelöscht.
    </p>


    <h3>7. Ihre Rechte als von der Datenverarbeitung Betroffener</h3>
    <p>
      Nach den anwendbaren Gesetzen haben Sie verschiedene Rechte bezüglich Ihrer personenbezogenen Daten. Möchten Sie
      diese Rechte geltend machen, so richten Sie Ihre Anfrage bitte per E-Mail oder per Post unter eindeutiger
      Identifizierung Ihrer Person an die in Ziffer 1 genannte Adresse.
    </p>
    <p>Nachfolgend finden Sie eine Übersicht über Ihre Rechte.</p>
    <h4>7.1 Recht auf Bestätigung und Auskunft</h4>
    <p>Sie haben das Recht auf eine übersichtliche Auskunft über die Verarbeitung Ihrer personenbezogenen Daten.</p>
    <p>
      Im Einzelnen: <br>
      Sie haben jederzeit das Recht, von uns eine Bestätigung darüber zu erhalten, ob Sie betreffende personenbezogene
      Daten verarbeitet werden. Ist dies der Fall, so haben Sie das Recht, von uns eine unentgeltliche Auskunft über
      die zu Ihnen gespeicherten personenbezogenen Daten nebst einer Kopie dieser Daten zu verlangen. Des Weiteren
      besteht ein Recht auf folgende Informationen:
    </p>
    <ol class="pl-4 list-decimal">
      <li>die Verarbeitungszwecke;</li>
      <li>die Kategorien personenbezogener Daten, die verarbeitet werden;</li>
      <li>
        die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt
        worden
        sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen
        Organisationen;
      </li>
      <li>
        falls möglich, die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls
        dies
        nicht möglich ist, die Kriterien für die Festlegung dieser Dauer;
      </li>
      <li>
        das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten oder
        auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese
        Verarbeitung;
      </li>
      <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
      <li>
        wenn die personenbezogenen Daten nicht bei Ihnen erhoben werden, alle verfügbaren Informationen über die
        Herkunft der Daten;
      </li>
      <li>
        das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und
        4
        DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die
        Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für Sie.
      </li>
    </ol>
    <br>
    <p>
      Werden personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt, so haben Sie
      das Recht, über die geeigneten Garantien gemäß Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet
      zu werden.
    </p>
    <h4>7.2 Recht auf Berichtigung</h4>
    <p>
      Sie haben das Recht, von uns die Berichtigung und ggf. auch Vervollständigung Sie betreffender personenbezogener
      Daten zu verlangen.
    </p>
    <p>
      Im Einzelnen: <br>
      Sie haben das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten
      zu verlangen. Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung
      unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.
    </p>
    <h4>7.3 Recht auf Löschung ("Recht auf Vergessenwerden")</h4>
    <p>In einer Reihe von Fällen sind wir verpflichtet, Sie betreffende personenbezogene Daten zu löschen.</p>
    <p>
      Im Einzelnen: <br>
      Sie haben gemäß Art. 17 Abs. 1 DSGVO das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene
      Daten unverzüglich gelöscht werden, und wir sind verpflichtet, personenbezogene Daten unverzüglich zu löschen,
      sofern einer der folgenden Gründe zutrifft:
    </p>
    <ol class="pl-4 list-decimal">
      <li>
        Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet
        wurden, nicht mehr notwendig.
      </li>
      <li>
        Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs. 1 S. 1 a) DSGVO
        oder Art.
        9 Abs. 2 a) DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
      </li>
      <li>
        Sie legen gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine
        vorrangigen
        berechtigten Gründe für die Verarbeitung vor, oder Sie legen gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen
        die
        Verarbeitung ein.
      </li>
      <li>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
      <li>
        Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem
        Unionsrecht oder dem
        Recht der Mitgliedstaaten erforderlich, dem wir unterliegen.
      </li>
      <li>
        Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft
        gemäß Art. 8 Abs. 1 DSGVO
        erhoben.
      </li>
    </ol>
    <br>
    <p>
      Haben wir die personenbezogenen Daten öffentlich gemacht und sind wir gemäß Art. 17 Abs. 1 DSGVO zu deren
      Löschung verpflichtet, so treffen wir unter Berücksichtigung der verfügbaren Technologie und der
      Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung
      Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie von ihnen die
      Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser
      personenbezogenen Daten verlangt haben.
    </p>
    <h4>7.4 Recht auf Einschränkung der Verarbeitung</h4>
    <p>
      In einer Reihe von Fällen sind Sie berechtigt, von uns eine Einschränkung der Verarbeitung Ihrer
      personenbezogenen Daten zu verlangen.
    </p>
    <p>
      Im Einzelnen: <br>
      Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden
      Voraussetzungen gegeben ist:
    </p>
    <ol class="pl-4 list-decimal">
      <li>
        die Richtigkeit der personenbezogenen Daten wird von Ihnen bestritten, und zwar für eine Dauer, die es
        uns
        ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,
      </li>
      <li>
        die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnten und
        stattdessen
        die Einschränkung der Nutzung der personenbezogenen Daten verlangt haben;
      </li>
      <li>
        wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie die Daten
        jedoch zur
        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
      </li>
      <li>
        Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben, solange noch nicht
        feststeht, ob
        die berechtigten Gründe unseres Unternehmens gegenüber den Ihren überwiegen.
      </li>
    </ol>
    <br>
    <h4>7.5 Recht auf Datenübertragbarkeit</h4>
    <p>
      Sie haben das Recht, Sie betreffende personenbezogene Daten maschinenlesbar zu erhalten, zu übermitteln, oder von
      uns übermitteln zu lasen.
    </p>
    <p>
      Im Einzelnen: <br>
      Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
      strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und Sie haben das Recht, diese Daten einem
      anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln, sofern
    </p>
    <ol class="pl-4 list-decimal">
      <li>
        die Verarbeitung auf einer Einwilligung gemäß Art. 6 Abs. 1 S. 1 a) DSGVO oder Art. 9 Abs. 2 a) DSGVO oder
        auf einem Vertrag gemäß Art. 6 Abs. 1 S. 1 b) DSGVO beruht und
      </li>
      <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
    </ol>
    <br>
    <p>
      Bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Absatz 1 haben Sie das Recht, zu erwirken, dass die
      personenbezogenen Daten direkt von uns einem anderen Verantwortlichen übermittelt werden, soweit dies technisch
      machbar ist.
    </p>

    <h4>7.6 Widerspruchsrecht</h4>
    <p>
      Sie haben das Recht, aus einer rechtmäßigen Verarbeitung Ihrer personenbezogenen Daten durch uns zu
      widersprechen, wenn sich dies aus Ihrer besonderen Situation begründet und unsere Interessen an der
      Verarbeitung nicht überwiegen.
    </p>
    <p>
      Im Einzelnen: <br>
      Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
      Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 S. 1 e) oder f) DSGVO
      erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Wir
      verarbeiten die personenbezogenen Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe
      für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die
      Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
    </p>
    <p>
      Werden personenbezogene Daten von uns verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht,
      jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger
      Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung
      steht.
    </p>
    <p>
      Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Sie
      betreffende Verarbeitung Sie betreffender personenbezogener Daten, die zu wissenschaftlichen oder
      historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DSGVO erfolgt, Widerspruch
      einzulegen, es sei denn, die Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden
      Aufgabe erforderlich.
    </p>

    <h4>7.7 Automatisierte Entscheidungen einschließlich Profiling</h4>
    <p>
      Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling
      – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in
      ähnlicher Weise erheblich beeinträchtigt.
    </p>
    <p>
      Eine automatisierte Entscheidungsfindung auf der Grundlage der erhobenen personenbezogenen Daten findet nicht
      statt.
    </p>

    <h4>7.8 Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</h4>
    <p>Sie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.</p>

    <h4>7.9 Recht auf Beschwerde bei einer Aufsichtsbehörde</h4>
    <p>
      Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres
      Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass
      die Verarbeitung der Sie betreffenden personenbezogenen Daten rechtswidrig ist.
    </p>

    <h3>8. Datensicherheit</h3>
    <p>
      Wir sind um die Sicherheit Ihrer Daten im Rahmen der geltenden Datenschutzgesetze und technischen Möglichkeiten
      maximal bemüht.
    </p>
    <p>
      Ihre persönlichen Daten werden bei uns verschlüsselt übertragen. Dies gilt für alle angebotenen Formulare. Wir
      nutzen das Kodierungssystem SSL (Secure Socket Layer), weisen jedoch darauf hin, dass die Datenübertragung im
      Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der
      Daten vor dem Zugriff durch Dritte ist nicht möglich.
    </p>
    <p>
      Zur Sicherung Ihrer Daten unterhalten wir technische und organisatorische Sicherungsmaßnahmen entsprechend Art.
      32 DSGVO, die wir immer wieder dem Stand der Technik anpassen.
    </p>
    <p>
      Wir gewährleisten außerdem nicht, dass unser Angebot zu bestimmten Zeiten zur Verfügung steht; Störungen,
      Unterbrechungen oder Ausfälle können nicht ausgeschlossen werden. Die von uns verwendeten Server werden
      regelmäßig sorgfältig gesichert.
    </p>

    <h3>9. Weitergabe von Daten an Dritte, Datenübertragung ins Nicht-EU-Ausland</h3>
    <p>Grundsätzlich verwenden wir Ihre personenbezogenen Daten nur innerhalb unseres Unternehmens.</p>
    <p>
      Wenn und soweit wir Dritte im Rahmen der Erfüllung von Verträgen einschalten (etwa Logistik-Dienstleister),
      erhalten diese personenbezogene Daten nur in dem Umfang, in welchem die Übermittlung für die entsprechende
      Leistung erforderlich ist.
    </p>
    <p>
      Für den Fall, dass wir bestimmte Teile der Datenverarbeitung auslagern („Auftragsverarbeitung“), verpflichten wir
      Auftragsverarbeiter vertraglich dazu, personenbezogene Daten nur im Einklang mit den Anforderungen der
      Datenschutzgesetze zu verwenden und den Schutz der Rechte der betroffenen Person zu gewährleisten.
    </p>
    <p>
      Eine Datenübertragung an Stellen oder Personen außerhalb der EU außerhalb des in dieser Erklärung in den Ziffern
      4 und 5 genannten Fällen findet nicht statt und ist nicht geplant.
    </p>

    <h3>10. Datenschutzbeauftragter</h3>
    <p>
      Sollten Sie noch Fragen oder Bedenken zum Datenschutz haben, so wenden Sie sich bitte an unseren
      Datenschutzbeauftragten:
    </p>
    <p>
      Sven Weschler<br>
      Iqanta GmbH<br>
      kontakt@iqanta.com
    </p>

    <h3>11. Änderungen an dieser Datenschutzerklärung</h3>
    <p>
      Werden neue Dienste oder Anbieter zum Betrieb dieser Website eingesetzt, behalten wir uns vor, diese
      Datenschutzerklärung anzupassen, um den rechtlichen Gegebenheiten gerecht zu werden. Diese angepasste
      Datenschutzerklärung gilt dann für Ihren erneuten Besuch dieser Website.
    </p>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>
h3 {
  @apply text-lg font-semibold;
}
h4 {
  @apply text-base font-medium;
}
</style>

import { trackEvent } from "@/application/utils/matomo";
import { useTagManager } from '@demv_systems/feu-tag-manager';

export function useTrackSearch(name: string, category: string, isCompany: boolean | null) {
  function trackSearch(action: string) {

    useTagManager().push({
      event: category,
      isCompany: isCompany !== null ? isCompany : false,
    });

    trackEvent({
      category: category,
      action: action,
      name: name,
    });
  }

  return { trackSearch }
}

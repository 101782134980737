// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _StateKeys = {
  ShouldReloadApp: 'ShouldReloadApp',
  ShouldBlockReload: 'ShouldBlockReload',
} as const;

export const setLocalStorage = (key: string, value: unknown, expire: number) => {
  setValue(key, { value, expire: Date.now() + expire * 1000 });
};

export const getLocalStorage = (key: string): null | string | number | boolean => {
  const data = getValue(key);
  if (!data) return null;
  const { value, expire } = data;
  if (Date.now() > expire) {
    localStorage.removeItem(key);
    return null;
  }
  return value;
};

export const getValue = (key: string) => {
  const itemString = localStorage.getItem(key);
  if (!itemString) return null;

  return JSON.parse(itemString);
}

export const setValue = (key: string, data: unknown) => {
  localStorage.setItem(key, JSON.stringify(data));
}

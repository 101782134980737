

import { isProductionEnv } from "@/application/utils/envInfo";
import { captureException } from "@sentry/vue";

export enum TrackEventCategory {
  LOGIN_VIA_MAIL = 'Login-via-Mail',
  DOCUMENT_UPLOAD = 'Dokument-Upload',
  CONTRACT = 'Vertrag',
  CHECKUP = 'Check-up',
  DOCUMENT_SEARCH = 'Dokumentensuche',
  CONTRACT_SEARCH = 'Vertragssuche',
  BANK_ACCOUNT = 'Bankkonto',
  CONTACT_DATA = 'Kontaktinformationen',
}

type TrackEventParameters = {
  /**
   * The category of the event
   */
  category: TrackEventCategory | string,
  /**
   * The action associated with the category
   */
  action: string,
  /**
   * The name of the event itself (optional)
   */
  name?: string,
  /**
   * The value associated with the event (optional)
   */
  value?: number
  /**
   * Whether tracking is enabled or not. Only enabled on production by default.
   */
  enabled?: boolean
};

/**
 * Instruct Matomo to track a certain event
 *
 * @param {TrackEventParameters} parameters
 */
export function trackEvent({ action, category, name, value, enabled = isProductionEnv }: TrackEventParameters): void {
  if (!enabled) return;
  try {
    window._paq.push(['trackEvent', category, action, name, value]);
  } catch (e) {
    captureException(e);
  }
}

import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { getContracts, getDepots } from '@/api/contracts/contractsApi';
import {
  type Contract,
  type Depot,
  type NormalizedDepot,
  type Status
} from '@/api/contracts/types';
import { isAfter, isBefore } from 'date-fns';
import { resolveRelations } from '@/application/utils/api/resolveRelations';
import { ContractState, type NestedContracts, type NestedDepots, type StringMap } from "@/contracts/types/types";
import { spartenMapper } from '@/application/utils/sparteFormatter';
import type { ContractOption } from "@/contracts/stores/types";

export const useContractStore = defineStore('contract', () => {
  const contractsInitialized = ref<boolean>(false);
  const depotsInitialized = ref(<boolean>false);
  const contracts = ref<Contract[]>([]);
  const contractsLoading = ref<boolean>(false);
  const contractsError = ref<Error | null>(null);
  const depots = ref<Depot[]>([]);
  const depotsLoading = ref<boolean>(false);
  const depotsError = ref<Error | null>(null);
  const lastContractsFetch = ref<Date | null>(null);
  const lastDepotsFetch = ref<Date | null>(null);
  const statuses = ref<StringMap>({});

  const totalCostPerMonth = computed(() => contracts.value
    .filter((contract: Contract) => {
      const { start, end } = contract.term ?? {};

      return !((start && isAfter(new Date(start), new Date()))
        || (end && isBefore(new Date(end), new Date())));
    })
    .reduce((accumulator, { owner, status, payment }) => (
      owner === null && status.id === ContractState.AKTIV
        ? accumulator + (payment?.monatlich || 0)
        : accumulator
    ), 0));

  const contractsMap = computed(() => new Map(contracts.value.map((item: Contract) => [item.id, item])));

  const contractOptions = computed((): ContractOption[] => contracts.value.map((contract) =>  ({
      label: spartenMapper(contract.product),
      value: contract.id,
      inactive: contract.status.id !== ContractState.AKTIV,
      statusId: contract.status.id,
      statusName: contract.status.name,
      number: contract.number,
      owner: contract.owner,
    })).sort((a, b) => a.statusId - b.statusId)
  );

  const contractOptionsOwnContracts = computed((): ContractOption[] =>
    [...contractOptions.value].filter(contract => contract.owner === null)
  );

  function isOutdated(lastFetch: Date | null, minutes: number) {
    if (!lastFetch) return true;

    const now = new Date();
    const diffInMinutes = (now.getTime() - lastFetch.getTime()) / (1000 * 60);

    return diffInMinutes > minutes;
  }

  function findDepot(contractId: number): Depot | undefined {
    return depots.value.find((depot: Depot) => depot.id === contractId);
  }

  function findContract (contractId: number): Contract | undefined {
    return contracts.value.find((contract: Contract) => contract.id === contractId);
  }

  function removeUserContract(userContractId: number): void {
    const index = contracts.value.findIndex((contract: Contract) => contract.userContractId === userContractId);
    if (index < 0) return;
    contracts.value.splice(index, 1);
  }

  function addStatuses(data: Status[]) {
    data.forEach((status) => {
      statuses.value[status.id] = status.name;
    })
  }

  async function fetchContracts(force: boolean = false, minutes: number = 5) {
    if ((contractsInitialized.value && !force && !isOutdated(lastContractsFetch.value, minutes)) || contractsLoading.value) {
      return;
    }

    contractsLoading.value = true;
    contractsError.value = null;

    const result = await getContracts();
    if (result instanceof Error) {
      contractsError.value = result;
    } else {
      const resolved = resolveRelations(result, { type: 'documentTypes' }) as NestedContracts;
      contracts.value = (resolved?.contracts|| []).map((contract: Contract) => {
        contract.spartenName = spartenMapper(contract.product);
        // [] propably broken type. can be null or object
        if (Array.isArray(contract.meta)) contract.meta = null
        return contract;
      });
      addStatuses(resolved?.statuses|| []);
    }

    contractsInitialized.value = true;
    contractsLoading.value = false;
    lastContractsFetch.value = new Date();
  }

  async function fetchDepots(force: boolean = false, minutes: number = 5) {
    if ((depotsInitialized.value && !force && !isOutdated(lastDepotsFetch.value, minutes)) || depotsLoading.value) {
      return;
    }

    depotsError.value = null;
    depotsLoading.value = true;
    const result = await getDepots();
    if (result instanceof Error) {
      depotsError.value = result;
    } else {
      const fondFinanzDepots = result.fondFinanzDepots.reduce(
        (acc: Pick<NormalizedDepot, "depot_evaluation" | "depotFonds" | "id" | "monthly_payment_rate">[], depot) => {
          const contract = findContract(depot.id);
          if (contract) {
            const merged = { ...depot, ...contract };
            merged.company = contract.company;
            acc.push(merged);
          }
          return acc;
        },
        [] as NormalizedDepot[]
      ) as NormalizedDepot[];

      result.depots = [...result.depots ?? [], ...fondFinanzDepots];
      const resolved = resolveRelations(result) as NestedDepots;

      depots.value = resolved?.depots ?? [];
      addStatuses(resolved?.statuses ?? []);
    }

    depotsLoading.value = false;
    depotsInitialized.value = true
    lastDepotsFetch.value = new Date();
  }

  async function fetchContractsAndDepots(force: boolean = false, minutes: number = 5) {
    await fetchContracts(force, minutes);
    await fetchDepots(force, minutes);
  }

  function $reset () {
    contractsInitialized.value = false;
    depotsInitialized.value = false;
    contracts.value = [];
    contractsLoading.value = false;
    contractsError.value = null;
    depots.value = [];
    depotsLoading.value = false;
    depotsError.value = null;
    lastContractsFetch.value = null;
  }

  return {
    $reset,
    contractsInitialized,
    depotsInitialized,
    contracts,
    contractsLoading,
    contractsError,
    depots,
    depotsLoading,
    depotsError,
    lastContractsFetch,
    statuses,

    totalCostPerMonth,
    contractsMap,
    contractOptions,
    contractOptionsOwnContracts,

    isOutdated,
    findDepot,
    findContract,
    removeUserContract,
    addStatuses,
    fetchContracts,
    fetchDepots,
    fetchContractsAndDepots,
  }
});

<template>
  <list
    :list="options"
    :class="[{row: horizontalOptions, 'mx-auto': horizontalOptions}, $attrs.class]"
    :after-classes="horizontalOptions ? [] : ['mb-2.5 pb-1.5 md:pb-0']"
    :before-classes="horizontalOptions ? [] : ['mt-2.5 pt-1.5 md:pt-0']"
  >
    <template #default="{ item, index }">
      <OptionItem
        :model-value="getValue(item.modelValue, index)"
        :icon="item.icon"
        :icon-class="item.iconClass"
        :checkbox="multiple"
        :class="{col: horizontalOptions}"
        class="flex items-center"
        @update:model-value="value => setValue(index, value)"
      >
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="item.text !== undefined ? item.text : item" />

        <PopoverIcon v-if="item.help !== undefined">
          <div
            v-for="line in item.help"
            :key="line"
            v-html="line"
          />
        </PopoverIcon>
        <!-- eslint-enable vue/no-v-html -->
      </OptionItem>
    </template>
  </list>
</template>

<script>
import { without } from 'lodash-es';

import { List } from '../List';
import PopoverIcon from '../PopoverIcon.vue';
import OptionItem from './OptionItem.vue';
import { props } from './props';

export default {
  components: {
    List,
    OptionItem,
    PopoverIcon,
  },

  inheritAttrs: false,

  props: {
    ...props,

    name: {
      type: String,
      default: null,
    },

    modelValue: {
      type: [Array, Number, Boolean],
      required: true,
      default: undefined,
    },
  },

  emits: ['update:modelValue'],

  methods: {
    update(value) {
      this.$emit('update:modelValue', value);
    },

    getValue(value, index) {
      const content = value !== undefined ? value : index;
      return this.multiple ? (this.modelValue || []).includes(content) : this.modelValue === content;
    },

    setValue(index, addOrRemove) {
      const { modelValue = index } = this.options[index];

      if (this.multiple) {
        const array = this.modelValue || [];

        this.update(addOrRemove ? array.concat(modelValue) : without(array, modelValue));
      } else {
        this.update(addOrRemove ? modelValue : null);
      }
    },
  },
};
</script>

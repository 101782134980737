import { getRouteNames, nameAndPath } from "@/application/router/utils/utils";
import type { RouteRecordRaw } from "vue-router";
import MainLayout from "@/application/layouts/MainLayout.vue";
import { Finanzmanager } from "@/application/router/types/types";
import StepView from "@/checkup/components/StepView.vue";
import { Step } from "@/checkup/utils/steps";
import Grunddaten from "@/checkup/components/steps/Grunddaten.vue";
import Beruf from "@/checkup/components/steps/Beruf.vue";
import Finanzen from "@/checkup/components/steps/Finanzen.vue";
import Wohnen from "@/checkup/components/steps/Wohnen.vue";
import Gesundheit from "@/checkup/components/steps/Gesundheit.vue";
import Fahrzeuge from "@/checkup/components/steps/Fahrzeuge.vue";
import Freizeit from "@/checkup/components/steps/Freizeit.vue";
import Partneruebersicht from "@/checkup/components/steps/Partneruebersicht.vue";
import Kinderuebersicht from "@/checkup/components/steps/Kinderuebersicht.vue";
import Abschluss from "@/checkup/components/steps/Abschluss.vue";
import Bestaetigung from "@/checkup/components/Bestaetigung.vue";

const checkupRoutes: RouteRecordRaw[] = [
  {
    path: '/check-up',
    component: MainLayout,
    meta: {
      title: 'Checkup',
      hideHeaderBackNav: true,
    },
    children: [
      {
        name: Finanzmanager.CHECKUP,
        path: '',
        component: StepView,
        children: [
          {
            ...nameAndPath(Step.GRUNDDATEN),
            component: Grunddaten,
          },
          {
            ...nameAndPath(Step.BERUF),
            component: Beruf,
          },
          {
            ...nameAndPath(Step.FINANZEN),
            component: Finanzen,
          },
          {
            ...nameAndPath(Step.WOHNEN),
            component: Wohnen,
            meta: {
              stepTitle: 'Wohnsituation',
            },
          },
          {
            ...nameAndPath(Step.GESUNDHEIT),
            component: Gesundheit,
            meta: {
              stepTitle: 'Gesundheitliches',
            },
          },
          {
            ...nameAndPath(Step.FAHRZEUGE),
            component: Fahrzeuge,
          },
          {
            ...nameAndPath(Step.FREIZEIT),
            component: Freizeit,
            meta: {
              stepTitle: 'Freizeit & Ausland',
            },
          },
          {
            ...nameAndPath(Step.PARTNER),
            component: Partneruebersicht,
          },
          {
            ...nameAndPath(Step.KINDER),
            component: Kinderuebersicht,
          },
          {
            ...nameAndPath(Step.ABSCHLUSS),
            component: Abschluss,
            meta: {
              stepTitle: 'Juhu, so gut wie geschafft!',
            },
          },
        ],
      },
      {
        path: '/check-up/Bestaetigung',
        name: 'Bestaetigung',
        component: Bestaetigung,
      },
    ]
  }
];

const checkupRouteNames = getRouteNames(checkupRoutes);

export { checkupRoutes, checkupRouteNames }

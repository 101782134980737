<template>
  <DsForm :validation-errors="validationErrors">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-4 md:flex-row justify-between">
        <DsFormGroup
          label="Straße"
          class="w-full"
          validation-name="street"
        >
          <DsInput
            v-model="form.street"
            immediate
            size="lg"
            @focus="resetError('street')"
          />
        </DsFormGroup>
        <DsFormGroup
          label="Hausnummer"
          class="w-full md:w-52"
          validation-name="houseNumber"
        >
          <DsInput
            v-model="form.houseNumber"
            immediate
            size="lg"
            @focus="resetError('houseNumber')"
          />
        </DsFormGroup>
      </div>
      <div class="flex flex-col gap-4 md:flex-row justify-between">
        <DsFormGroup
          label="Postleitzahl"
          class="w-full md:w-52"
          validation-name="zipCode"
        >
          <DsInput
            v-model="form.zipCode"
            immediate
            mask="#####"
            size="lg"
            @focus="resetError('zipCode')"
          />
        </DsFormGroup>
        <DsFormGroup
          label="Stadt"
          class="w-full"
          validation-name="city"
        >
          <DsInput
            v-model="form.city"
            immediate
            size="lg"
            @focus="resetError('city')"
          />
        </DsFormGroup>
      </div>
      <DsFormGroup
        label="Telefonnummer"
        validation-name="phone"
      >
        <DsInput
          v-model="form.phone"
          immediate
          size="lg"
          @focus="resetError('phone')"
        />
      </DsFormGroup>
      <DsFormGroup
        validation-name="mobile"
        label="Mobilfunknummer"
      >
        <DsInput
          v-model="form.mobile"
          immediate
          size="lg"
          @focus="resetError('mobile')"
        />
      </DsFormGroup>

      <DsFormGroup
        label="E-Mail Adresse"
        validation-name="email"
      >
        <DsInput
          v-model="form.email"
          immediate
          placeholder="Ihre E-Mail-Adresse"
          icon="envelope"
          type="email"
          size="lg"
          required
          class="mb-8"
          @focus="resetError('email')"
        />
      </DsFormGroup>
    </div>

    <div class="text-right mt-10">
      <DsButton
        class="w-full sm:w-auto"
        size="lg"
        :handler="sendForm"
      >
        Änderung speichern
      </DsButton>
    </div>
  </DsForm>
</template>

<script setup lang="ts">
import {
  DsButton,
  DsForm,
  DsFormGroup,
  DsInput,
} from '@demvsystems/design-components';

import { updateContactData } from '@/api/user/userApi';
import type { UserContactDataRequestPayload } from '@/api/user/types';
import { useApiWithFormData } from '@/application/composables/apiWithFormData';
import { useFormHelper } from '@/application/composables/formHelper';
import { ConversionFunction, convertValues } from '@/application/utils/form/convertValues';
import { trackEvent } from "@/application/utils/matomo";
import { TrackEventCategory } from "@/application/utils/matomo/events";
import { storeToRefs } from "pinia";
import { useUserDataStore } from "@/user/store/userDataStore";
import { watch } from "vue";
import { useTagManager } from '@demv_systems/feu-tag-manager';

const { userData } = storeToRefs(useUserDataStore());

const {
  form,
  getMutated,
} = useFormHelper<UserContactDataRequestPayload>({
  email: undefined,
  street: undefined,
  houseNumber: undefined,
  zipCode: undefined,
  city: undefined,
  phone: undefined,
  mobile: undefined,
});

watch(userData, () => {
    form.email = userData.value?.pwEmail ?? undefined;
    form.street = userData.value?.mainAddress?.street ?? undefined;
    form.houseNumber = userData.value?.mainAddress?.houseNumber ?? undefined;
    form.zipCode = userData.value?.mainAddress?.zipCode ?? undefined;
    form.city = userData.value?.mainAddress?.city ?? undefined;
    form.phone = userData.value?.phone ?? undefined;
    form.mobile = userData.value?.mobile ?? undefined;
}, { immediate: true });

const { submit, validationErrors, resetError } = useApiWithFormData(
  updateContactData,
  'Ihre Daten wurden erfolgreich geändert.',
  () => {
    userData.value = {
      pwEmail: form.email ?? null,
      mainAddress: {
        street: form.street ?? null,
        houseNumber: form.houseNumber ?? null,
        zipCode: form.zipCode ?? null,
        city: form.city ?? null,
      },
      phone: form.phone ?? null,
      mobile: form.mobile ?? null,
    };
  }
);

const sendForm = () => {
  submit(
    convertValues(getMutated(), { zipCode: ConversionFunction.StringToNumberOrNull }) as UserContactDataRequestPayload,
  );
  useTagManager().push({ event: TrackEventCategory.CONTACT_DATA, action: 'speichern' })
  trackEvent({ category: TrackEventCategory.CONTACT_DATA, action: 'Speichern', name: 'click' });
}
</script>

<template>
  <popover
    ref="target"
    :default-show="false"
    :show="show"
    :width="320"
    :icon="false"
    :options="options"
    class="inline-block"
    @hide="hide"
  >
    <template #reference>
      <DsIcon
        name="question-circle"
        variant="regular"
        class="ml-1.5 cursor-pointer text-gray-500 bg-white rounded-full"
        @click.stop="show = !show"
      />
    </template>

    <div class="text-left">
      <slot>
        <div>
          {{ content }}
        </div>
      </slot>
    </div>
  </popover>
</template>

<script setup lang="ts">

import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core'
import Popover from './Popover.vue';
import { DsIcon } from '@demvsystems/design-components';

interface Props {
  content?: string;
  options?: object;
}

withDefaults(defineProps<Props>(), {
  content: '',
  options: () => ({}),
});

const show = ref(false);
const target = ref(null);

function hide() {
  show.value = false;
}

onClickOutside(target, () => hide())
</script>

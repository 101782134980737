<template>
  <PageContentWrapper>
    <div class="flex flex-col items-center gap-12">
      <h1 class="m-0">
        Bedarfe
      </h1>

      <div
        class="gap-4 lg:gap-5 mx-auto"
        :class="`grid ${gridCols}`"
      >
        <ThemenMenuItem
          v-for="group in themen"
          :key="group.title"
          :image="group.image"
          :themen="group.themen"
          :title="group.title"
        />
      </div>

      <DsButton
        :to="{ name: MeinBedarf.VERLAUF }"
        variant="secondary"
      >
        Vorherige Beratungen anzeigen
      </DsButton>
    </div>
  </PageContentWrapper>
</template>

<script setup lang="ts">
import { DsButton } from '@demvsystems/design-components';
import { MeinBedarf } from '@/application/router/types/types';
import { storeToRefs } from 'pinia';
import { useBedarfStore } from '@/bedarf/store/bedarfStore';
import ThemenMenuItem from "@/bedarf/components/themenmenu/ThemenMenuItem.vue";
import { computed } from 'vue';
import PageContentWrapper from '@/application/layouts/ContentWrapper/PageContentWrapper.vue';

const { themen } = storeToRefs(useBedarfStore());

const size = computed(() => Object.keys(themen.value).length);

const gridCols = computed(() => {
  switch (size.value) {
    case 2:
      return 'grid-cols-1 md:grid-cols-2';
    case 3:
      return 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3';
    case 4:
      return 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4';
    case 5:
      return 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3';
    default:
      return 'grid-cols-1';
  }
})
</script>

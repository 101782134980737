import { ZodError } from "zod";
import { captureException } from "@sentry/vue";
import { getResponseMessage } from "@/api/lib/utils";
import { isUnloading } from "@/application/utils/isUnloading";

export type ApiError = NetworkError | AbortError | FetchError | ValidationError;

export class NetworkError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NetworkError";
  }
}

export class AbortError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "AbortError";
  }
}

export class FetchError extends Error {
  constructor(message: string, public status?: number) {
    super(`${status} ${message}`);
    this.name = "FetchError";
    this.status = status;
  }
}

export class UnauthorizedError extends Error {
  constructor(message: string = 'Unauthorized') {
    super(message);
    this.name = "UnauthorizedError";
  }
}

export class ForbiddenError extends Error {
  constructor(message: string = 'Die Aktion ist nicht erlaubt') {
    super(message);
    this.name = "Forbidden";
  }
}

export class CsrfTokenError extends Error {
  constructor(message: string = "CSRF token mismatch") {
    super(message);
    this.name = "CsrfTokenError";
  }
}

export class NotAGuestError extends Error {
  constructor(message: string = "Not a guest") {
    super(message);
    this.name = "NotAGuestError";
  }
}

export class ThrottleError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ThrottleError";
  }
}

export class ExpiredClientSignatureError extends Error {
  constructor(message: string = 'Client signature expired') {
    super(message);
    this.name = "ExpiredClientSignatureError";
  }
}

export class ValidationError extends Error {
  constructor(message: string, public errors: Record<string, string[]>) {
    super(message);
    this.name = "ValidationError";
    this.errors = errors ?? {};
  }
}

export async function makeApiErrorFromResponse(response: Response): Promise<ApiError | null> {
  if (response.status === 401) {
    if ((await response.json())?.clientSignature === 'expired') return new ExpiredClientSignatureError();
    return new UnauthorizedError();
  } else if (response.status === 403) {
    return new ForbiddenError();
  } else if (response.status === 419) {
    return new CsrfTokenError();
  } else if (response.status === 429) {
    const data = await response.json();
    return new ThrottleError(data.message);
  } else if (response.status === 422) {
    const data = await response.json();
    return new ValidationError(data.message, data.errors);
  } else if (!response.ok) {
    const message = (await getResponseMessage(response)) ?? `HTTP error: ${response.statusText}`;
    return new FetchError(message, response.status);
  }

  return null;
}

export function makeApiErrorFromError(error: unknown) {
  if (isUnloading()) {
    return new NetworkError("Document unloading, request aborted.");
  } else if (error instanceof DOMException && error.name === "AbortError") {
    return new AbortError("The request was aborted.");
  } else if (error instanceof TypeError && error.message === "Failed to fetch") {
    return new NetworkError("Network error occurred.");
  } else if (error instanceof ZodError) {
    captureException(error);
    return error;
  } else {
    captureException(error);
    return new FetchError("An unknown error occurred.");
  }
}

<script setup lang="ts">
import { DsIcon } from '@demvsystems/design-components';
import { Finanzmanager } from '@/application/router/types/types';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useHandlungStore } from "@/actions/store/actionStore";
import { usePolling } from "@/application/composables/usePolling.ts";

const route = useRoute();
const { actionsByState, priorityAction } = storeToRefs(useHandlungStore());

const label = computed(() => {
  if (!priorityAction.value) return null;

  const title = priorityAction.value.title;
  const num = actionsByState.value.open?.length ?? 0;

  switch (num) {
    case 1:
      return title;
    case 2:
      return `${title} und 1 offene Handlung`;
    default:
      return `${title} und ${num - 1} offene Handlungen`;
  }
});

const show = computed(() => priorityAction.value && !route.matched.some(route => route.path === '/handlungen'));

usePolling().listenFor('handlung', useHandlungStore().fetchActionsSilently);
</script>

<template>
  <div
    v-if="show"
    class="bg-red-100 z-50 shadow-xs py-3 bg-opacity-70"
  >
    <div class="container flex flex-row items-center gap-3 text-sm md:text-base">
      <DsIcon
        name="circle-info"
        variant="light"
        class="text-red-800"
      />
      <span class="text-red-800">
        <RouterLink :to="{ name: Finanzmanager.HANDLUNGEN }">
          <span class="font-semibold text-red-800 underline underline-offset-2">{{ label }}</span>
        </RouterLink>
        in Ihrer Inbox.
      </span>
    </div>
  </div>
</template>

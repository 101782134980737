<script setup lang="ts">
import { DsIcon } from '@demvsystems/design-components';
import MaklervollmachtModal from "@/Maklervollmacht/components/MaklervollmachtModal.vue";
import { ref } from "vue";

const show = ref(false);
</script>

<template>
  <div class="bg-blue-100 z-50 shadow-xs py-3 bg-opacity-70">
    <MaklervollmachtModal v-model:show="show" />
    <div class="container flex flex-row items-center gap-3 text-sm md:text-base text-blue-800">
      <DsIcon
        name="circle-info"
        variant="light"
      />
      <span>
        Es liegt noch kein Maklervertrag bzw. -vollmacht vor. Diesen können Sie
        <span class="font-semibold underline cursor-pointer" @click="show = true">jetzt anfordern und unterschreiben</span>.
      </span>
    </div>
  </div>
</template>

<template>
  <PageContentWrapperCompact>
    <ContractsView @open-modal="showCreateModal = true" />

    <CreateContractModal
      :show="showCreateModal"
      @close="showCreateModal = false"
    />

    <FloatingActionButton
      class="fixed right-4 md:hidden"
      rounded
      @click="showCreateModal = true"
    />
  </PageContentWrapperCompact>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import FloatingActionButton from '@/application/components/FloatingAction/FloatingActionButton.vue';
import CreateContractModal from '@/contracts/components/CreateContractModal.vue';
import ContractsView from "@/contracts/components/ContractsView.vue";
import PageContentWrapperCompact from "@/application/layouts/ContentWrapper/PageContentWrapperCompact.vue";

const showCreateModal = ref(false);
</script>

<template>
  <PageContentWrapper>
    <ContractDetails
      v-if="contract && depot === undefined"
      :contract="contract"
    />

    <DepotDetails
      v-if="depot"
      :depot="depot"
    />
  </PageContentWrapper>
</template>

<script setup lang="ts">
import { watch, computed } from 'vue';
import { useRoute } from 'vue-router';

import { useUnreadHintStore } from '@/application/store/unreadHintStore';
import { UnreadHintType } from '@/api/unreadHint/types';
import { useUnreadHint } from '@/application/composables/unreadHint';
import { useContractStore } from "@/contracts/stores/contractStore";
import ContractDetails from "@/contracts/components/ContractDetails.vue";
import DepotDetails from "@/contracts/components/DepotDetails.vue";
import PageContentWrapper from '@/application/layouts/ContentWrapper/PageContentWrapper.vue';

const { params } = useRoute();
const contractId = Number(params.id)
const { isUnread } = useUnreadHint(UnreadHintType.Contract, contractId);

const contractStore = useContractStore()

const depot = computed(() => contractStore.findDepot(contractId));
const contract = computed(() => contractStore.findContract(contractId));

watch(isUnread, (val) => val &&
  setTimeout(async () => {
    await useUnreadHintStore().removeUnreadHints([{ type: UnreadHintType.Contract, object_id: Number(params.id) }], true)
  }, 1000), { immediate: true }
);
</script>

<template>
  <PageContentWrapper>
    <div class="-mx-4 md:mx-0">
      <ShallowCard
        no-hover
      >
        <h3 class="text-gray-900" v-text="news.title" />
        <div class="flex">
          <DsBadge>
            {{ news.createdAt }}
          </DsBadge>
        </div>
        <div
          class="markdown-base-styling flex flex-col gap-2 mt-10 mb-3"
          v-html="markdown"
        />
      </ShallowCard>
    </div>
  </PageContentWrapper>
</template>

<script setup lang="ts">
import { marked } from 'marked';
import { computed, onMounted } from 'vue';

import type { News } from '@/api/news/types';
import { useUnreadHintStore } from "@/application/store/unreadHintStore";
import { UnreadHintType } from "@/api/unreadHint/types";
import ShallowCard from '@/contracts/components/ShallowCard.vue';
import { DsBadge } from '@demvsystems/design-components';
import PageContentWrapper from '@/application/layouts/ContentWrapper/PageContentWrapper.vue';

interface Props {
  news: News,
  content: string,
}

const props = defineProps<Props>();

const markdown = computed(() => marked.parse(props.content));

onMounted(() => {
  useUnreadHintStore().removeUnreadHints(
    [{ type: UnreadHintType.News, object_id: props.news.id }],
    true
  );
});

</script>

<style scoped>
::v-deep(.markdown-base-styling) ul {
  padding-left: 20px;
  list-style-type: disc;
}

::v-deep(.markdown-base-styling) ul li {
  margin-bottom: 0.25rem;
}
</style>

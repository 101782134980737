<template>
  <div
    class="rounded border w-72 bg-white"
    :class="Number(value) > 0 ? 'border-blue-500 border-2' : 'border-gray-200'"
    :style="Number(value) > 0 ? '' : 'padding: 1px'"
  >
    <div class="flex flex-row justify-between items-center p-2">
      <div class="flex flex-col items-start pl-2">
        <SvgVue
          class="h-6"
          :class="Number(value) > 0 && 'fill-secondary'"
          :icon="`icomoon/${iconMapping[fahrzeugId]}`"
        />
        <label
          :for="name"
          class="mt-1 inline-block"
        >
          {{ label }}
        </label>
      </div>
      <CountInput
        :value="value"
        :name="name"
        :error="error"
        :min="0"
        :max="20"
        @input="$emit('input', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">

import SvgVue from '@/application/components/SvgVue';
import CountInput from '@/checkup/components/CountInput.vue';
import { Fahrzeug } from '@/checkup/utils/demvWerte';

interface Props {
  value: number | null;
  name: string;
  label: string;
  fahrzeugId: Fahrzeug;
  error?: unknown[]
}

withDefaults(defineProps<Props>(), {
  value: null,
  error: undefined,
});

defineEmits(['input']);

const iconMapping: Record<Exclude<Fahrzeug, Fahrzeug.SCHLEPPER>, string> = {
  [Fahrzeug.PKW]: 'auto',
  [Fahrzeug.MOTORRAD]: 'motorrad',
  [Fahrzeug.MOFA_MOPED]: 'mofa',
  [Fahrzeug.ANHAENGER]: 'anhaenger',
  [Fahrzeug.QUAD]: 'quad',
  [Fahrzeug.LKW]: 'lkw',
  [Fahrzeug.OLDTIMER]: 'oldtimer',
  [Fahrzeug.WOHNMOBIL]: 'wohnmobil',
  [Fahrzeug.E_BIKE]: 'ebike',
  [Fahrzeug.FAHRRAD]: 'fahrrad',
  [Fahrzeug.DROHNE]: 'drohne',
  [Fahrzeug.BOOT]: 'boot',
};
</script>

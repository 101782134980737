<template>
  <div class="grid gap-6 md:grid-cols-2">
    <div>
      <ShallowCard no-hover>
        <slot name="title" />

        <div
          v-if="hasHeader"
          class="my-2.5"
          data-testid="hasHeaderSlot"
        >
          <slot name="header" />
        </div>

        <slot name="main" />
      </ShallowCard>
    </div>

    <div v-if="hasExtra" class="min-w-0">
      <slot name="extra" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';

import ShallowCard from '@/contracts/components/ShallowCard.vue';

const slots = useSlots();

const hasHeader = computed(() => slots.header !== undefined);
const hasExtra = computed(() => slots.extra !== undefined);
</script>
